import React, { Fragment, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import {
  Button,
  Group,
  H2,
  Markdown,
  Modal,
  ModalFooter,
  ModalHeader,
  StackCard,
  Task,
} from '@pocketrn/rn-designsystem';
import {
  Acknowledgment,
  ClientCustomForm,
  ClientSignedCustomForm,
  User,
} from '@pocketrn/entities/dist/core';
import { Person } from '@pocketrn/entities/dist/community';
import Signature from '../Signature';
import { LocalePrimer } from '@pocketrn/localizer';
import { sessionUserController } from '../../../utils/userStateInit';
import { getManagedProperty } from '../../../utils/managedProperty';
import { ProfileContext } from '../../routes/profile/ProfileContext';
import { useSelector } from 'react-redux';
import {
  selectSessionPerson,
  selectSessionUserCustomFormsLoading,
  selectSessionUserProviderMap,
  selectSessionUserSignedCustomFormsLoading,
  selectSessionUserSignedCustomFormsMap,
} from '../../../apps/user-state';
import { useFilteredCustomForms } from '../../../utils/useFilteredCustomForms';
import { selectActionLink } from '../../../state/redux/core/selectors';

const locale = 'core.acknowledgments';

type SignatureStatus = 'valid' | 'needsResign';

interface CustomForm {
  unsignedCopy: ClientCustomForm;
  signedCopy?: ClientSignedCustomForm
  signatureStatus?: SignatureStatus;
  title: string;
};

interface Props {
  user: User;
  person: Person;
  coSigner?: Person;
  localePrimer: LocalePrimer;
  invitedUid?: string;
  mustConsentToContinue?: boolean
  selectMode?: boolean;
  isDesignatedSinger?: boolean;
  onPreSelectForms?: (forms: string[]) => void;
  onNameChange?: (firstName: string, lastName: string, isCoSigner: boolean) => void;
}

const UserCustomForms:FunctionComponent<Props> = (props): ReactElement => {
  const {
    user,
    person,
    coSigner,
    localePrimer,
    invitedUid,
    selectMode,
    mustConsentToContinue,
    isDesignatedSinger,
  } = props;
  const ctx = useContext(ProfileContext);
  const sessionProviderMap = useSelector(selectSessionUserProviderMap);
  const [ groupedForms, setGroupedForms ] = useState<Record<string, ClientCustomForm[]>>({});
  const [ viewedForm, setViewedForm ] = useState<CustomForm | undefined>();
  const [ signLoading, setSignLoading ] = useState(false);
  const [ selectedFormsToSendLink, setSelectedFormsToSendLink ] = useState<string[]>([]);
  const patientUid = invitedUid ?? ctx.managedUid;
  // @NOTE: we still need this for the session user when we want to block them from using the app.
  // and that's because AcknowledgmentModal in App doesn't have access to the ProfileContext.
  const sessionSignedCustomFormsMap = useSelector(selectSessionUserSignedCustomFormsMap);
  const sessionCustomFormsLoading = useSelector(selectSessionUserCustomFormsLoading);
  const sessionSignedCustomFormsLoading = useSelector(selectSessionUserSignedCustomFormsLoading);
  const sessionPerson = useSelector(selectSessionPerson);
  const actionLink = useSelector(selectActionLink);
  const [ signedViaActionLink, setSignedViaActionLink ] = useState([] as string[]);
  const shouldUseSessionData = !!actionLink || !!mustConsentToContinue;
  const viaAcknowledgmentModal = !actionLink && mustConsentToContinue;

  const customFormsMap = useFilteredCustomForms(shouldUseSessionData);
  const signedCustomFormsMap = shouldUseSessionData ?
    sessionSignedCustomFormsMap : ctx.signedCustomFormsMap;
  const loading = shouldUseSessionData ?
    sessionCustomFormsLoading || sessionSignedCustomFormsLoading :
    ctx.loading;
  const currentLegalName = ctx.person?.fullLegalName(localePrimer) ??
    sessionPerson?.fullLegalName(localePrimer);

  const shouldNotShow = (form: ClientCustomForm) => {
    const _form = buildCustomForm(form);
    // @NOTE: if the form is signed via action link, keep it shown.
    if (signedViaActionLink.includes(form.root.id)) {
      return false;
    }
    // @NOTE: if already signed, don't show it for action links.
    if (actionLink && _form.signatureStatus === 'valid') {
      return true;
    }
    // @NOTE: only show required if the user must sign to continue.
    if (viaAcknowledgmentModal && !form.root.required) {
      return true;
    }
    // @NOTE: if already signed, don't show it for ack modal.
    if (viaAcknowledgmentModal && _form.signatureStatus === 'valid') {
      return true;
    }
    return false;
  };

  const buildGroupedForms = () => {
    const providersMap: Record<string, ClientCustomForm[]> = {};
    Object.values(customFormsMap).forEach(form => {
      if (shouldNotShow(form)) {
        return;
      }
      if (!providersMap[form.providerId]) {
        providersMap[form.providerId] = [];
      }
      providersMap[form.providerId].push(form);
    });
    setGroupedForms(providersMap);
  };

  const getFormsWithProvider = (forms: ClientCustomForm[]) => {
    return forms.map(f => getForm(f));
  };

  const getCaption = (customForm: CustomForm) => {
    let text = undefined;
    const requiredForm = customForm.unsignedCopy.root.required;
    const color = requiredForm ? 'error' : undefined;
    if (customForm.signedCopy?.root.isExpired) {
      text = localePrimer.translate(locale, 'expired');
    } else if (customForm.signedCopy?.root.isVersionUpdated(
      customForm.unsignedCopy?.root.version,
    )) {
      text = localePrimer.translate(locale, 'outdatedVersion');
    } else if (!customForm.unsignedCopy.root.required) {
      text = localePrimer.translate(locale, 'optional');
    }
    return { text, color };
  };

  const selectFormToSendActionLink = (form: ClientCustomForm) => {
    const _selectedFormsToSendLink = [...selectedFormsToSendLink];
    const index = _selectedFormsToSendLink.indexOf(form.root.id);
    if (index > -1) {
      _selectedFormsToSendLink.splice(index, 1);
    } else {
      _selectedFormsToSendLink.push(form.root.id);
    }
    setSelectedFormsToSendLink(_selectedFormsToSendLink);
    if (props.onPreSelectForms) {
      props.onPreSelectForms(_selectedFormsToSendLink);
    }
  };

  const getForm = (form: ClientCustomForm) => {
    const customForm = buildCustomForm(form);
    const caption = getCaption(customForm);
    if (selectMode) {
      if (customForm.signatureStatus === 'valid') {
        return null;
      }
      return (
        <StackCard
          key={form.root.id}
          text={customForm.title}
          subText={caption.text}
          subTextColor={caption.color as any}
          onSelect={() => selectFormToSendActionLink(form)}
          testIdRoot={`userCustomForm-stackcard-${form.root.id}`}
          selected={selectedFormsToSendLink.includes(form.root.id)
            || customForm.unsignedCopy.root.required}
          onSelectDisabled={customForm.unsignedCopy.root.required}
        />
      );
    }
    return (
      <Task
        key={form.root.id}
        active={false}
        complete={customForm?.signatureStatus === 'valid'}
        onToggle={() => handleToggle(customForm)}
        localePrimer={localePrimer}
        label={customForm.title}
        onViewTextClick={() => setViewedForm(customForm)}
        testIdRoot={`userCustomForm-task-${form.root.id}`}
        loading={loading || signLoading}
        caption={caption.text}
        captionColor={caption.color as any}
      />
    );
  };

  const buildCustomForm = (customForm: ClientCustomForm): CustomForm => {
    const signedCopy = signedCustomFormsMap[customForm.root.id];
    let signatureStatus: SignatureStatus | undefined;
    if (!signedCopy || !currentLegalName) {
      signatureStatus = undefined;
    } else {
      const isValidSignature = signedCopy.isValid(currentLegalName, customForm.root.version);
      if (isValidSignature) {
        signatureStatus = 'valid';
      } else {
        signatureStatus = 'needsResign';
      }
    }
    const title = (signedCopy && signatureStatus === 'valid') ?
      signedCopy.translate(user.languages).title :
      customForm.translate(user.languages).title;
    return {
      unsignedCopy: customForm,
      signedCopy: signedCopy ?? undefined,
      signatureStatus: signatureStatus,
      title,
    };
  };

  const handleToggle = (customForm: CustomForm) => {
    const isSigned = customForm.signatureStatus === 'valid';
    if (isSigned) {
      setViewedForm(customForm);
    } else if (customForm.unsignedCopy.root.requiresSignature) {
      setViewedForm(customForm);
    } else if (!isDesignatedSinger) {
      setViewedForm(customForm);
    } else {
      signForm(customForm.unsignedCopy.root.id);
    }
  };

  const getBody = (): string => {
    if (!viewedForm) {
      return '';
    }
    if (viewedForm.signedCopy && viewedForm.signatureStatus === 'valid') {
      return viewedForm.signedCopy.translate(user.languages).body;
    }
    return viewedForm.unsignedCopy.translate(user.languages).body;
  };

  const getConsentDetails = (formId: string) => {
    const body = customFormsMap[formId]?.translate(user.languages).body;
    return {
      acceptedBy: person.fullLegalName(localePrimer),
      // @TODO: replace body when piped text is available
      body,
      coAcceptedBy: coSigner?.fullLegalName(localePrimer),
    };
  };

  const signForm = (formId: string) => {
    _signForm(formId);
  };

  const _signForm = async (formId: string) => {
    setSignLoading(true);
    setSignedViaActionLink([ ...signedViaActionLink, formId ]);
    const { acceptedBy, body, coAcceptedBy } = getConsentDetails(formId);
    const managed = getManagedProperty(patientUid);
    await sessionUserController.signCustomForm(
      formId,
      acceptedBy,
      body,
      coAcceptedBy,
      managed,
    );
    // @NOTE: the managed user custom forms are in the ProfileContext, we don't need to update redux.
    if (!managed) {
      await sessionUserController.getMyCustomForms();
    } else {
      await ctx.refreshCustomForms(invitedUid);
    }
    setSignLoading(false);
    if (viewedForm) {
      setViewedForm(undefined);
    }
  };

  const getAcknowledgment = (): Acknowledgment => {
    if (!viewedForm?.signedCopy) {
      throw new Error('signedViewedForm is undefined');
    }
    return new Acknowledgment(
      viewedForm?.signedCopy.root.customFormId,
      viewedForm?.signedCopy.root.createdAt,
      viewedForm?.signedCopy.acceptedBy,
      viewedForm?.signedCopy.coAcceptedBy ?? undefined,
    );
  };

  const getFooter = () => {
    const buttons: ReactElement[] = [];
    if (!viewedForm) {
      return undefined;
    }
    // @NOTE: Signature component handles the accept button when the form requires a signature.
    if (viewedForm.unsignedCopy.root.requiresSignature) {
      return undefined;
    } else if (viewedForm?.signatureStatus === 'valid' || !isDesignatedSinger) {
      buttons.push(
        <Button
          key={0}
          type="secondary"
          onClick={() => setViewedForm(undefined)}
          testIdRoot="userCustomForm-task-modalFooter-done-btn"
        >
          {localePrimer.translate('shared.done')}
        </Button>,
      );
    } else {
      buttons.push(
        <Button
          key={0}
          type="primary"
          onClick={() => signForm(viewedForm.unsignedCopy.root.id)}
          testIdRoot="userCustomForm-task-modalFooter-accept-btn"
        >
          {localePrimer.translate(locale, 'signature.acceptBtn')}
        </Button>,
        <Button
          key={1}
          type="text"
          onClick={() => setViewedForm(undefined)}
          testIdRoot="userCustomForm-task-modalFooter-cancel-btn"
        >
          {localePrimer.translate('shared.cancel')}
        </Button>,
      );
    }
    return (
      <ModalFooter
        buttons={buttons}
      />
    );
  };

  const getProviderName = (providerId: string) => {
    // @NOTE: newly invited users won't have an active providerId until they finish registration
    // but we can use the sessionProviderMap to get the provider name, because the patients
    //  will be part of the session user's provider.
    const name = ctx.providerMap[providerId]?.name ?? sessionProviderMap[providerId]?.name;
    return localePrimer.translate(locale, 'forProvider')(name);
  };

  const checkDefaultFormsToSend = () => {
    if (!currentLegalName) return;
    const formsToSend = Object.values(customFormsMap).filter(form => {
      return !form.root.required &&
          (!signedCustomFormsMap[form.root.id]
          || !signedCustomFormsMap[form.root.id].isValid(currentLegalName, form.root.version));
    }).map(form => form.root.id);
    setSelectedFormsToSendLink(formsToSend);
    if (props.onPreSelectForms) {
      props.onPreSelectForms(formsToSend);
    }
  };

  useEffect(() => {
    buildGroupedForms();
    checkDefaultFormsToSend();
  }, [ customFormsMap, signedCustomFormsMap ]);

  useEffect(() => {
    ctx.refreshCustomForms(invitedUid);
  }, [invitedUid]);

  return (
    <Fragment>
      {Object.keys(groupedForms).map((provider, index) => {
        return (
          <Group
            key={index}
            title={getProviderName(provider)}
            testIdRoot={`userCustomForm-group-${provider}`}
            divider={index !== Object.keys(groupedForms).length - 1}
          >
            {getFormsWithProvider(groupedForms[provider])}
          </Group>
        );
      })}
      {viewedForm && (
        <Modal
          onClose={() => setViewedForm(undefined)}
          hide={!viewedForm}
          fullScreen
          header={
            <ModalHeader
              title={localePrimer.translate(locale, 'overline')}
              onClose={() => setViewedForm(undefined)}
            />
          }
          loading={signLoading}
          footer={getFooter()}
        >
          <H2
            align="center"
            text={viewedForm.title}
          />
          <Markdown
            size="sm"
            markdown={getBody()}
          />
          {viewedForm.unsignedCopy.root.requiresSignature && isDesignatedSinger && (
            <Signature
              loading={signLoading}
              user={user}
              person={person}
              onAccept={viewedForm.signatureStatus === 'valid' ?
                undefined :
                () => signForm(viewedForm.unsignedCopy.root.id)
              }
              onCancel={() => setViewedForm(undefined)}
              coSigner={coSigner}
              acknowledgment={viewedForm.signatureStatus === 'valid' ? getAcknowledgment() : undefined}
              onNameChange={props.onNameChange}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default UserCustomForms;
