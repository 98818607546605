import { ClientCustomNotified, ClientParticipant, ScheduledMeeting, ScheduledMeetingStatus, ClientCallType, ClientCustomCallType } from '@pocketrn/entities/dist/meeting';
import { AccountType, Provider, Region, User } from '@pocketrn/entities/dist/core';
import { AvailableNurseTimes, MeetingSDK } from '../../services/firebase/MeetingSDK';
import { Firestore } from 'firebase/firestore';
import { Person } from '@pocketrn/entities/dist/community';
import { SessionUserController } from '../../../user-state';
import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { UserController } from '../../../../state/controllers/User.controller';
import { getManagedProperty } from '../../../../utils/managedProperty';
import { CursorId } from '@pocketrn/rn-designsystem';

// @NOTE: Redux does not export its Store type.
type ReduxStore = any;

export class ScheduledMeetingsController {
  public sdk: MeetingSDK;
  public store: ReduxStore;
  public firestore: Firestore;
  public userController: UserController;
  public sessionUserController: SessionUserController;

  constructor(
    sdk: MeetingSDK,
    store: ReduxStore,
    firestore: Firestore,
    userController: UserController,
    sessionUserController: SessionUserController,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.firestore = firestore;
    this.userController = userController;
    this.sessionUserController = sessionUserController;
  }

  public async getScheduledMeetings(options?: { limit?: number, managed?: ManagedProperty })
  : Promise<{
    scheduledMeetings: ScheduledMeeting[];
    usersMap: Record<string, User>;
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
  }> {
    return await this.sdk.getScheduledMeetings(options);
  }

  public async getCanceledMeetings(options?: {
    searchOptions?: { limit?: number, nextPageCursorId?: CursorId },
    managed?: ManagedProperty,
  }) : Promise<{
    canceledMeetings: ScheduledMeeting[];
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
    usersMap: Record<string, User>;
    nextPageCursorId: CursorId;
  }> {
    return await this.sdk.getCanceledMeetings(options);
  }

  public async getAvailableNurses(
    providerId: string,
    requestedCallType: ClientCallType,
    region: Region,
    startAt: Date,
    endAt: Date,
    rescheduledMeetingId?: string,
    overrideSchedulingWindow?: boolean,
    managed?: ManagedProperty,
  ): Promise<AvailableNurseTimes[]> {
    return await this.sdk.getAvailableNurses(
      providerId,
      requestedCallType,
      region,
      startAt,
      endAt,
      rescheduledMeetingId,
      overrideSchedulingWindow,
      managed,
    );
  }

  public async createScheduledMeeting(
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
    overrideSchedulingWindow?: boolean,
  ): Promise<ScheduledMeeting> {
    const managed = this.getManaged(participants);
    return await this.sdk.createScheduledMeeting(
      providerId,
      meetingDate,
      requestedCallType,
      requestorNote,
      region,
      participants,
      customNotified,
      managed,
      overrideSchedulingWindow,
    );
  }

  public async updateScheduledMeetingStatus(
    meetingId: string,
    status: ScheduledMeetingStatus,
    cancelationReason: string | null,
  ): Promise<void> {
    await this.sdk.updateScheduledMeetingStatus(meetingId, status, cancelationReason);
  }

  public getManaged(participants: ClientParticipant[]): ManagedProperty | undefined {
    const user = this.sessionUserController.getStoredActiveUser();
    const patientParticipant = participants.find(p => p.root.accountType === AccountType.Patient);
    if (!patientParticipant || patientParticipant.uid === user?.uid) {
      return undefined;
    }
    return {
      uid: patientParticipant.uid,
      accountType: patientParticipant.root.accountType,
      // @NOTE: We explicitely do not add the providerId because the patient may not have that
      // provider. We may be using the caregiver's instead.
    };
  }

  public async rescheduleMeeting(
    meetingId: string,
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
    overrideSchedulingWindow?: boolean,
  ): Promise<ScheduledMeeting> {
    const managed = this.getManaged(participants);
    return await this.sdk.rescheduleMeeting(
      meetingId,
      providerId,
      meetingDate,
      requestedCallType,
      requestorNote,
      region,
      participants,
      customNotified,
      managed,
      overrideSchedulingWindow,
    );
  };

  public async updateParticipantsAndNotified(
    meetingId: string,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
  ): Promise<void> {
    const managed = this.getManaged(participants);
    await this.sdk.updateParticipantsAndNotified(
      meetingId,
      participants,
      customNotified,
      managed,
    );
  };

  // @TODO: this is temporary until we have an endpoint for Nurses to get their patients
  public async getPatients(): Promise<{
    usersMap: Record<string, User>,
    personsMap: Record<string, Person>,
  }> {
    const [ mtgs, pastMts ] = await Promise.all([
      this.getScheduledMeetings(),
      this.sdk.getMeetingsHistory(),
    ]);
    const patientUids = mtgs.scheduledMeetings.map(m => m.patientUid);
    const pastPatientUids = pastMts.meetings.map(m => m.requestor.patientUid);
    const allPatientUids = Array.from(new Set(patientUids.concat(pastPatientUids)));
    const usersMap = {} as Record<string, User>;
    const personsMap = {} as Record<string, Person>;
    await Promise.all(allPatientUids.map(async uid => {
      const managed = getManagedProperty(uid);
      // @NOTE: we don't want to get the users from the meetings because the backend
      // does not return all the required fields, such as stripe.
      const u = await this.userController.retrieveUserWithoutSettingSession(managed);
      const p = await this.userController.retrievePersonWithoutSettingSession(managed);
      usersMap[uid] = u;
      personsMap[uid] = p;
    }));
    return { usersMap, personsMap };
  }
}
