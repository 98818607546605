import React, { Fragment, FunctionComponent, ReactElement, useState } from 'react';
import {
  Button,
  ButtonGroup,
  SignatureBlock,
} from '@pocketrn/rn-designsystem';
import { Person } from '@pocketrn/entities/dist/community';
import localePrimer from '../../utils/localePrimer';
import { Acknowledgment, User } from '@pocketrn/entities/dist/core';
import SignatureUpdateForm from './SignatureUpdateForm';
import NameChangePolicyModal from '../shared/NameChangePolicyModal';

const locale = 'core.acknowledgments.signature';

interface Props {
  loading: boolean;
  user: User;
  person: Person;
  coSigner?: Person;
  onNameChange?: (firstName: string, lastName: string, isCoSigner: boolean) => void;
  onAccept?: () => void;
  onCancel: () => void;
  acknowledgment?: Acknowledgment;
  coSignatureMode?: 'read-only' | 'new-signature';
}

const Signature: FunctionComponent<Props> = (props): ReactElement => {
  const { user, person, coSigner, loading, coSignatureMode } = props;
  const [ showEditLegalName, setShowEditLegalName ] = useState(false);
  const [ showEditCoLegalName, setShowEditCoLegalName ] = useState(false);
  const [ showNameChangeModal, setShowNameChangeModal ] = useState(false);

  const getLegalName = (_person: Person): string => {
    return _person.name(
      localePrimer,
      {
        accountTypeOfCaller: user.activeAccountType,
        accountTypeOfPerson: user.activeAccountType,
        isLegalAcknowledgement: true,
      },
    );
  };

  const handleNameChange = (firstName: string, lastName: string, isCoSigner: boolean) => {
    if (!props.onNameChange) {
      throw new Error('cannot change name unless onNameChange is set');
    }
    const nameHasChanged = firstName !== person.firstName || lastName !== person.lastName;
    if (isCoSigner && coSigner) {
      setShowEditCoLegalName(false);
    } else {
      if (nameHasChanged) {
        setShowNameChangeModal(true);
      }
      setShowEditLegalName(false);
    }
    if (nameHasChanged) {
      props.onNameChange(firstName, lastName, isCoSigner);
    }
  };

  const legalName = getLegalName(person);
  const coSignerLegalName = coSigner ? getLegalName(coSigner) : undefined;
  const hasCoSigner = coSigner || props.acknowledgment?.coAcceptedBy;

  return (
    <Fragment>
      { !showEditLegalName &&
        <SignatureBlock
          label={localePrimer.translate(
            locale,
            hasCoSigner ? 'acceptedByLabel' : 'signedByLabel',
          )}
          localePrimer={localePrimer}
          signature={
            props.acknowledgment || !props.onAccept ? props.acknowledgment?.acceptedBy : legalName}
          onEditSignature={props.onNameChange ? () => setShowEditLegalName(true) : undefined}
          testIdRoot="signature-signatureBlock"
          signedOn={hasCoSigner ? undefined : props.acknowledgment?.acceptedAt}
        />
      }
      {showEditLegalName &&
        <SignatureUpdateForm
          title={localePrimer.translate(locale, hasCoSigner ? 'acceptedByLabel' : 'signedByLabel')}
          person={person}
          loading={loading}
          onNameChange={(f: string, l: string) => handleNameChange(f, l, false)}
          onCancel={() => setShowEditLegalName(false)}
        />
      }
      {/* When editing co-signer legal name */}
      {(showEditCoLegalName && coSigner) &&
        <SignatureUpdateForm
          title={localePrimer.translate(locale, 'signedByLabel')}
          person={coSigner}
          loading={loading}
          onNameChange={(f: string, l: string) => handleNameChange(f, l, true)}
          onCancel={() => setShowEditCoLegalName(false)}
        />
      }
      {/* When already cosigned */}
      {coSignatureMode === 'read-only' && (
        <SignatureBlock
          label={localePrimer.translate(locale, 'signedByLabel')}
          localePrimer={localePrimer}
          signature={props.acknowledgment?.coAcceptedBy}
          testIdRoot="signature-coAcceptedBySignatureBlock"
          signedOn={props.acknowledgment?.acceptedAt}
        />
      )}
      {/* When there's new cosigner */}
      {coSignatureMode === 'new-signature' && (
        <SignatureBlock
          label={localePrimer.translate(locale, 'signedByLabel')}
          localePrimer={localePrimer}
          signature={coSignerLegalName}
          testIdRoot="signature-coAcceptedBySignatureBlock"
          signedOn={props.acknowledgment?.acceptedAt}
          onEditSignature={props.onNameChange ? () => setShowEditCoLegalName(true) : undefined}
        />
      )}
      <ButtonGroup noBottomPadding>
        {!!props.onAccept &&
          <Button
            type="primary"
            onClick={props.onAccept}
            loading={props.loading}
            testIdRoot="signature-accept-btn"
            disabled={
              showEditCoLegalName ||
              showEditLegalName ||
              !legalName ||
              (!coSignerLegalName && !!coSigner)
            }
          >
            {localePrimer.translate(locale, 'acceptBtn')}
          </Button>
        }
        <Button
          type={props.onAccept ? 'text' : 'secondary'}
          onClick={props.onCancel}
          loading={props.loading}
          testIdRoot="signature-cancel-btn"
          disabled={showEditCoLegalName || showEditLegalName}
        >
          {localePrimer.translate('shared', props.onAccept ? 'cancel' : 'done')}
        </Button>
      </ButtonGroup>
      <NameChangePolicyModal
        show={showNameChangeModal}
        onConfirm={() => setShowNameChangeModal(false)}
        localePrimer={localePrimer}
        owner="self"
      />
    </Fragment>
  );
};

export default Signature;
