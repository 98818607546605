import React, { Suspense, lazy, ReactElement, useState, useEffect, Fragment, useCallback } from 'react';
import {
  Route,
  useLocation,
  Routes,
  Navigate,
  useNavigate,
  useMatch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingRoute from './components/routes/LoadingRoute';
import { useHasBeenInactive, useIsActiveInstance, useLocalStorage } from '@pocketrn/rn-designsystem';
import { logger, LogLevel } from '@pocketrn/client/dist/app-logger';
import {
  featureFlagController,
  firebaseUserController,
  sessionUserController,
} from './utils/userStateInit';
import { CoreRoute } from './entities/Router';
import { HelpRootPage } from './apps/help-app';
import localePrimer from './utils/localePrimer';
import { helpRouter, supportTicketController, tutorialController } from './utils/helpAppInit';
import { meetingController, meetingRouter, profileController, scheduledMeetingsController, shiftController, userMatchController } from './utils/meetingAppInit';
import { AccountType, AccountStatus, isRequestor, isAcceptor } from '@pocketrn/entities/dist/core';
import { selectIsRegistering } from './state/redux/registration/selectors';
import {
  MeetingModal,
  selectInQueue,
  MeetingRouter,
  selectUserMatchMeetingsList,
} from './apps/meeting-app';
import {
  botResponseLibrary,
  chatbotController,
  messagesController,
} from './utils/messagingAppInit';
import {
  selectSessionUser,
  selectFirebaseUser,
} from './apps/user-state/index';
import PageLayout from './components/shared/PageLayout';
import SubscriptionNoticeModal from './apps/meeting-app/components/modal/SubscriptionNoticeModal';
import ImpersonationBanner from './apps/technician-app/components/ImpersonationBanner';
import SubscriptionBanner from './apps/meeting-app/components/main/SubscriptionBanner';
import AcknowledgmentsModal from './components/shared/AcknowledgmentsModal';
import { selectFeatureFlagsInitialized } from './apps/feature-flags/src/selectors';
import FeatureFlagTestBanner from './apps/technician-app/components/FeatureFlagTestBanner';
import StartScheduledMeetingModal from './apps/meeting-app/components/modal/StartScheduledMeetingModal';
import { registrationController, userController } from './state/controllers';
import { selectPromptReauthentication, selectSessionAccountLoading, selectSessionUserAccounts } from './apps/user-state/src/session/selectors';
import LoginPage from './components/login/LoginPage';
import { useAcknowledgmentsState } from './utils/useAcknowledgmentsState';
import { getFirebaseConfig } from './utils/firebase';
import formValidator from './utils/fv';
import { relevantMeetings } from './utils/myMeetings';

logger.logLevel = LogLevel.Debug;

/* eslint-disable @typescript-eslint/promise-function-async */
const MainPage = lazy(() => import('./components/routes/MainPageRoute'));
const ProfileRoutes = lazy(() => import('./components/routes/profile/ProfileRoutes'));
const MeetingsRoutes = lazy(() => import('./components/routes/MeetingsRoutes'));
const MeetingRoutes = lazy(() => import('./components/routes/MeetingRoutes'));
const Register = lazy(() => import('./components/routes/RegisterRoute'));
const VerifyEmail = lazy(() => import('./components/routes/VerifyEmailRoute'));
const Login = lazy(() => import('./components/routes/LoginRoute'));
const Inactive = lazy(() => import('./components/routes/InactiveRoute'));
const AccountSuspended = lazy(() => import('./components/routes/AccountSuspendedRoute'));
const ActionLinkRoot = lazy(() => import('./components/routes/actionLinks/ActionLinkRoutes'));

const App = (): ReactElement => {
  const isActiveInstance = useIsActiveInstance();
  const hasBeenInactive = useHasBeenInactive();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectSessionUser);
  const promptReauth = useSelector(selectPromptReauthentication);
  const accounts = useSelector(selectSessionUserAccounts);
  const accountsLoading = useSelector(selectSessionAccountLoading);
  const firebaseUser = useSelector(selectFirebaseUser);
  const inQueue = useSelector(selectInQueue);
  const firebaseUserExists = !!firebaseUser;
  const userExists = !!user;
  const [ firebaseUserInitialized, setFirebaseUserInitialized ] = useState(false);
  const [ userMatchInitialized, setUserMatchInitialized ] = useState(false);
  const inviteCodeRouteMatch = useMatch(CoreRoute.InviteCode);
  const { anyAcknowledgmentSigned } = useAcknowledgmentsState(localePrimer);
  const actionLinkToken = useLocalStorage('_actionLinkToken')?.toString();
  const [ isActionLink, setIsActionLink ] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const googleAPIScope = urlParams.get('scope')?.includes('https://www.googleapis.com/auth') ? urlParams.get('scope') : undefined;
  const googleAPICode = !googleAPIScope ? undefined : urlParams.get('code');
  const [ reroutedToMeeting, setReroutedToMeeting ] = useState('');

  const vitalBanners: ReactElement[] = [
    <ImpersonationBanner key="impersonationBanner" />,
    <FeatureFlagTestBanner key="featureFlagTestBanner" />,
  ];

  const fullyRegisteredBanners: ReactElement[] = [
    ...vitalBanners,
    <SubscriptionBanner key="subscriptionBanner" />,
  ];

  const featureFlagsInitialized = useSelector(selectFeatureFlagsInitialized);
  const hasVerifiedEmail = firebaseUser && firebaseUser?.emailVerified;
  const isLoading = (
    (!firebaseUserExists && !firebaseUserInitialized) ||
    (firebaseUserExists && !userExists && hasVerifiedEmail) ||
    (!featureFlagsInitialized && userExists) ||
    (!user?.activeProviderId && accountsLoading)
  );
  const suspendedAccounts = accounts.filter(a => a.status === AccountStatus.Suspended);
  const activeAccountWasSuspended = suspendedAccounts.length > 0 && user && !user.activeProviderId;
  const loggedOut = !firebaseUserExists && firebaseUserInitialized;
  const newUser = user && !anyAcknowledgmentSigned(user);
  const needToRegister = (
    (user && firebaseUserExists && newUser) ||
    (!user && firebaseUserExists) ||
    (!user?.activeProviderId && !activeAccountWasSuspended)
  );
  const isRegistering = useSelector(selectIsRegistering);
  const needToFinishRegistration = needToRegister || isRegistering;
  const inProgressMeetings = useSelector(selectUserMatchMeetingsList);

  const firebaseUserLoadingFinished = () => {
    setFirebaseUserInitialized(true);
  };

  useEffect(() => {
    // @NOTE: This is our solution so we don't have to create our own custom email action handler
    // for Firebase Auth email templates (verify email, reset password, etc.)
    // https://firebase.google.com/docs/auth/custom-email-handler.
    // We redirect the https://app.pocketrn.com/__/auth/action URL to the firebase's own
    // email action handler URL.
    const url = new URL(window.location.href);
    if (url.pathname === '/__/auth/action') {
      const subDomain = getFirebaseConfig().projectId;
      const newUrl = new URL(`https://${subDomain}.firebaseapp.com/__/auth/action`);
      url.searchParams.forEach((value, key) => newUrl.searchParams.set(key, value));
      window.location.replace(newUrl.toString());
    }
    if (url.pathname.startsWith('/link/')) {
      setIsActionLink(true);
    } else {
      setIsActionLink(false);
    }
  }, [location]);

  useEffect(() => {
    if (inviteCodeRouteMatch) {
      const { providerId, inviteCode } = inviteCodeRouteMatch.params;
      const inviteCodeData = {
        providerId: providerId ?? '',
        inviteCode: inviteCode ?? '',
      };
      registrationController.setInviteCodeData(inviteCodeData);
      if (!needToFinishRegistration) {
        navigate(CoreRoute.ManageClinics);
      }
    }
  }, [ inviteCodeRouteMatch, needToFinishRegistration ]);

  useEffect(() => {
    if (!user || !user.activeAccountType) return;
    featureFlagController.retrieveFlags(user?.activeProviderId);
    if (user.activeAccountType === AccountType.Technician) return;
    userController.subscribeToSnapshotCore();
    userMatchController.subscribeToSnapshotMeetings();
    return function cleanup() {
      userController.unsubscribeFromSnapshotCore();
      userMatchController.unsubscribeFromSnapshotMeetings();
    };
  }, [user?.uid]);

  useEffect(() => {
    firebaseUserController.initFirebaseAuth(firebaseUserLoadingFinished);
  }, []);

  useEffect(() => {
    // @NOTE: After long periods of inactivity the app's pings and snapshots can
    // stop so we refresh to make sure the app reflects the current state.
    // We also want to log out the user for HIPAA compliance, if possible.
    const handleInactiveState = async () => {
      if (hasBeenInactive) {
        try {
          await sessionUserController.logout();
        } catch (err) {
          logger.logError(err);
        }
        window.location.reload();
      }
    };
    handleInactiveState();
  }, [hasBeenInactive]);

  const extractScopeName = (scope: string): string => {
    const scopeName = scope.split('/').pop();
    return scopeName ?? '';
  };

  // @NOTE: this has to be in App.tsx because after consenting to using GoogleAPIs
  // the browser will refresh and page and that will redirect to the root of the app.
  const confirmConsentToGoogleAPI = useCallback(async () => {
    if (!googleAPICode || !googleAPIScope) return;
    const scopeName = extractScopeName(googleAPIScope);
    try {
      await sessionUserController.confirmConsentToGoogleAPI(
        googleAPICode,
        scopeName,
      );
      await sessionUserController.retrieveUser();
      navigate(CoreRoute.EditCalendarPreferences);
    } catch (error) {
      logger.logError(error);
    }
  }, [ googleAPICode, googleAPIScope ]);

  useEffect(() => {
    confirmConsentToGoogleAPI();
  }, [confirmConsentToGoogleAPI]);

  const retrieveUser = async () => {
    await sessionUserController.init();
  };

  const isQueuedUser = (): boolean => {
    if (!user) {
      return true;
    }
    return isRequestor(user.activeAccountType) || isAcceptor(user.activeAccountType);
  };

  const rerouteIfMatched = async () => {
    if (!isQueuedUser()) return;
    if (!inQueue && user) {
      await userMatchController.init(true);
      setUserMatchInitialized(true);
    }
  };

  const rerouteToMeeting = useCallback(() => {
    if (!isQueuedUser()) {
      return;
    }
    if (!user) return;
    const options = { state: { shouldGoBackToHomePage: true } };
    const _inProgressMeetings = relevantMeetings(inProgressMeetings, user);
    if (_inProgressMeetings.length === 1) {
      const alreadyRoutedOrEnded = _inProgressMeetings[0].id === reroutedToMeeting ||
        _inProgressMeetings[0].endedAt;
      if (_inProgressMeetings[0].acceptor.uid === user?.uid && !alreadyRoutedOrEnded) {
        setReroutedToMeeting(_inProgressMeetings[0].id);
        navigate(MeetingRouter.getMeetingRoute(_inProgressMeetings[0].id), options);
      } else if (_inProgressMeetings[0].requestor.uid === user?.uid &&
        _inProgressMeetings[0].startedAt && !alreadyRoutedOrEnded) {
        setReroutedToMeeting(_inProgressMeetings[0].id);
        navigate(MeetingRouter.getMeetingRoute(_inProgressMeetings[0].id), options);
      }
    }
  }, [ inProgressMeetings, user ]);

  useEffect(() => {
    rerouteToMeeting();
  }, [rerouteToMeeting]);

  useEffect(() => {
    if (!firebaseUserInitialized || !firebaseUser) return;
    if (!userExists) {
      retrieveUser();
      return;
    };
    if (!userMatchInitialized && isQueuedUser()) {
      rerouteIfMatched();
    }
  }, [
    firebaseUserInitialized,
    firebaseUser,
    userExists,
    userMatchInitialized,
    // @NOTE Needed so that switching from a technician to a meeting account won't lock the user in a loading state.
    // and that would happen because of: isQueuedUser and userMatchInitialized checks.
    user?.activeAccountType,
  ]);

  if (promptReauth.type) {
    return (
      <LoginPage reauth={{
        email: promptReauth.email ?? undefined,
        type: promptReauth.type ?? 'unknown',
      }}
      />
    );
  }

  if (isLoading) {
    return <LoadingRoute />;
  }

  if (isActionLink || actionLinkToken) {
    return (
      <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
        <Suspense>
          <Routes>
            <Route path={CoreRoute.ActionLinkRoot} element={<ActionLinkRoot />} />
            <Route path="*" element={<ActionLinkRoot />} />
          </Routes>
        </Suspense>
      </PageLayout>
    );
  }

  if (loggedOut) {
    return (
      <Suspense fallback={<LoadingRoute />}>
        <Routes>
          <Route path={CoreRoute.Login} element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Suspense>
    );
  }

  if (!isActiveInstance) {
    return (
      <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
        <Suspense>
          <Routes>
            <Route path="*" element={<Inactive />} />
          </Routes>
        </Suspense>
      </PageLayout>
    );
  }

  // @NOTE: to be finished once we have the Zoom integration
  // const isUsingZoomVideoSession = false;
  // if (isUsingZoomVideoSession) {
  //   return (
  //     <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
  //       <Suspense>
  //         <Routes>
  //           <Route path={MeetingRoute.Meeting} element={
  //             <SessionRoute localePrimer={localePrimer} messagesController={messagesController} />}
  //           />
  //         </Routes>
  //       </Suspense>
  //     </PageLayout>
  //   );
  // }

  if (!hasVerifiedEmail) {
    return (
      <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
        <Suspense>
          <Routes>
            <Route path={CoreRoute.VerifyEmail} element={<VerifyEmail />} />
            <Route path="*" element={<Navigate to={CoreRoute.VerifyEmail} replace />} />
          </Routes>
        </Suspense>
      </PageLayout>
    );
  }

  if (needToFinishRegistration) {
    return (
      <Suspense>
        <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
          <Routes>
            <Route path={CoreRoute.Register} element={<Register />} />
            <Route path="*" element={<Navigate to={CoreRoute.Register} replace />} />
          </Routes>
        </PageLayout>
      </Suspense>
    );
  }

  if (!userMatchInitialized && isQueuedUser()) {
    return <LoadingRoute />;
  }

  if (activeAccountWasSuspended) {
    return (
      <PageLayout localePrimer={localePrimer} banners={vitalBanners}>
        <Suspense>
          <Routes>
            <Route
              path={CoreRoute.AccountSuspended}
              element={<AccountSuspended {...{} as any} />}
            />
            <Route path="*" element={<Navigate to={CoreRoute.AccountSuspended} />} />
          </Routes>
        </Suspense>
      </PageLayout>
    );
  }

  return (
    <Fragment>
      <PageLayout
        localePrimer={localePrimer}
        banners={fullyRegisteredBanners}
        drawer={{
          messagesController,
          chatbotController,
          localePrimer,
          botResponseLibrary,
        }}
      >
        <Suspense>
          <Routes>
            <Route path={CoreRoute.HelpRoot} element={
              <HelpRootPage
                localePrimer={localePrimer}
                router={helpRouter}
                tutorialController={tutorialController}
                supportTicketController={supportTicketController}
              />}
            />
            <Route path={CoreRoute.ProfileRoot} element={<ProfileRoutes />} />
            <Route path={CoreRoute.MainPageRoot} element={<MainPage />} />
            <Route path={CoreRoute.MeetingRoot} element={<MeetingRoutes
              localePrimer={localePrimer}
              userMatchController={userMatchController}
              router={meetingRouter}
              profileController={profileController}
              meetingController={meetingController}
              scheduledMeetingsController={scheduledMeetingsController}
              shiftController={shiftController}
              supportTicketController={supportTicketController}
              formValidator={formValidator}
              featureFlagController={featureFlagController}
              sessionUserController={sessionUserController}
            />}
            />
            <Route path={CoreRoute.MeetingsRoot} element={<MeetingsRoutes
              localePrimer={localePrimer}
              userMatchController={userMatchController}
              router={meetingRouter}
              profileController={profileController}
              meetingController={meetingController}
              scheduledMeetingsController={scheduledMeetingsController}
              shiftController={shiftController}
              supportTicketController={supportTicketController}
              formValidator={formValidator}
              featureFlagController={featureFlagController}
              sessionUserController={sessionUserController}
            />}
            />
            <Route path="*" element={<Navigate to={CoreRoute.MainPage} replace />} />
          </Routes>
        </Suspense>
      </PageLayout>
      <MeetingModal localePrimer={localePrimer} userMatchController={userMatchController} />
      <StartScheduledMeetingModal
        localePrimer={localePrimer}
        userMatchController={userMatchController}
      />
      <SubscriptionNoticeModal localePrimer={localePrimer} />
      <AcknowledgmentsModal localePrimer={localePrimer} />
    </Fragment>
  );
};

export default App;
