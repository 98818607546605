import { Person } from '@pocketrn/entities/dist/community';
import {
  Account,
  AccountFactory,
  AccountType,
  ActionLinkFactory,
  ClientActionLink,
  ClientActionLinkJson,
  ClientCustomForm,
  ClientSignedCustomForm,
  Provider,
  ProviderFactory,
  User,
  UserFactory,
} from '@pocketrn/entities/dist/core';
import { ManagedProperty, NotFound, PermissionDenied, PromptEmailPasswordReauthentication, PromptReauthenticationAfterLogOut } from '@pocketrn/client/dist/entity-sdk';
import { FeatureFlagController } from '../../../feature-flags/src/controller';
import { FirebaseUserController } from '../firebase/controller';
import { ActionKey, REDUCER_KEY, SessionActions, SessionReauthType, sessionUserActions } from './actions';
import { SessionUserState } from './reducer';
import { SessionUserSDK } from './sdk';
import { Counter } from '@pocketrn/rn-designsystem';
import { isLocal } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';
import { RegistrationActions } from '../../../../state/redux/registration/actions';
import { CoreActions } from '../../../../state/redux/core/actions';
import { ClientCustomCallType } from '@pocketrn/entities/dist/meeting';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class SessionUserController {
  public store: ReduxStore;
  public firebaseUserController: FirebaseUserController;
  public featureFlagController: FeatureFlagController;
  public sessionUserSDK: SessionUserSDK;
  private counter: Counter;

  constructor(
    sessionUserSDK: SessionUserSDK,
    firebaseUserController: FirebaseUserController,
    featureFlagController: FeatureFlagController,
    store: ReduxStore,
  ) {
    this.sessionUserSDK = sessionUserSDK;
    this.store = store;
    this.firebaseUserController = firebaseUserController;
    this.featureFlagController = featureFlagController;
    this.counter = new Counter();
  }

  public async initLocal(): Promise<void> {
    if (!isLocal) {
      logger.logError(new Error('cannot initLocal if not local'));
      return;
    }
    await this.sessionUserSDK.initLocal();
  }

  public async init(options?: { ignoreFirebaseAuth?: boolean }): Promise<void> {
    const firebaseUser = this.firebaseUserController.getStoredFirebaseUser();
    if (!firebaseUser && !options?.ignoreFirebaseAuth) {
      throw new Error('Unexpected state: no firebase user set');
    }
    // @NOTE: email/password has not had email verified yet.
    if (!firebaseUser?.emailVerified && !options?.ignoreFirebaseAuth) {
      this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.User));
      return;
    }
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Person, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Provider, true));
    await this.refreshAccounts({ retrievePerson: true, retrieveUser: true });
    await this.getMyCustomForms();
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, false));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Person, false));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, false));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Provider, false));
  }

  public async retrieveUser(managed?: ManagedProperty): Promise<User | undefined> {
    try {
      if (!managed) {
        this.store.dispatch(SessionActions.unsetPromptReauthentication());
      }
      const user = await this.sessionUserSDK.getUser(managed);
      if (!managed) {
        this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.User, user));
      }
      return user;
    } catch (e) {
      if (managed) {
        throw e;
      }
      if (e instanceof NotFound || e instanceof PermissionDenied) {
        this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.User));
        return undefined;
      } else if (e instanceof PromptReauthenticationAfterLogOut) {
        this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.User));
        this.store.dispatch(SessionActions.setPromptReauthentication(
          SessionReauthType.Unknown,
        ));
      } else if (e instanceof PromptEmailPasswordReauthentication) {
        this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.User));
        this.store.dispatch(SessionActions.setPromptReauthentication(
          SessionReauthType.EmailPassword,
          e.email,
        ));
      } else {
        throw e;
      }
    }
  }

  public async retrievePerson(managed?: ManagedProperty): Promise<Person | undefined> {
    try {
      const person = await this.sessionUserSDK.getPerson(managed);
      if (!managed) {
        this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.Person, person));
      }
      return person;
    } catch (e) {
      if (managed) {
        throw e;
      }
      if (
        e instanceof NotFound ||
        e instanceof PermissionDenied ||
        e instanceof PromptReauthenticationAfterLogOut ||
        e instanceof PromptEmailPasswordReauthentication
      ) {
        this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.Person));
        return undefined;
      } else {
        throw e;
      }
    }
  }

  public async retrieveAccounts(providerId?: string, managed?: ManagedProperty): Promise<{
    accounts: Account[] | undefined;
    providers: Provider[] | undefined;
    customCallTypes: ClientCustomCallType[] | undefined;
  }> {
    try {
      const {
        accounts,
        providers,
        customCallTypes,
      } = await this.sessionUserSDK.getAccounts(providerId, managed);
      if (!managed) {
        this.store.dispatch(sessionUserActions.setListEntities(ActionKey.Account, accounts));
        providers.map(provider => {
          this.store.dispatch(
            sessionUserActions.setMapEntity(ActionKey.Provider, provider.id, provider),
          );
        });
        this.store.dispatch(
          sessionUserActions.setListEntities(ActionKey.CustomCallType, customCallTypes),
        );
      }
      return { accounts, providers, customCallTypes };
    } catch (e) {
      if (managed) {
        throw e;
      }
      if (
        e instanceof NotFound ||
        e instanceof PermissionDenied ||
        e instanceof PromptReauthenticationAfterLogOut ||
        e instanceof PromptEmailPasswordReauthentication
      ) {
        return { accounts: undefined, providers: undefined, customCallTypes: undefined };
      } else {
        throw e;
      }
    }
  }

  public async refreshAccounts(
    options?: {
      retrieveUser?: boolean,
      retrievePerson?: boolean,
    },
  ): Promise<void> {
    let user = this.getStoredActiveUser();
    const [{ accounts, providers }, _, _user ] = await Promise.all([
      this.retrieveAccounts(),
      options?.retrievePerson ? this.retrievePerson() : undefined,
      !user || options?.retrieveUser ? this.retrieveUser() : undefined,
    ]);
    if (_user) {
      user = _user;
    }
    if (user && accounts && providers) {
      const activeAccount = this.extractActiveAccount(accounts, user);
      const activeProvider = this.extractActiveProvider(providers, user);
      await this.setStoredActiveAccountAndProvider(activeAccount, activeProvider);
    } else {
      await this.setStoredActiveAccountAndProvider(undefined, undefined);
    }
  }

  private async setStoredActiveAccountAndProvider(
    account: Account | undefined,
    provider?: Provider | undefined,
  ): Promise<void> {
    if (account) {
      await this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.Account, account));
    } else {
      await this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.Account));
    }
    if (provider) {
      await this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.Provider, provider));
    } else {
      await this.store.dispatch(sessionUserActions.unsetActiveEntity(ActionKey.Provider));
    }
  }

  private extractActiveAccount(
    accounts: Account[],
    user: User,
  ): Account | undefined {
    for (const account of accounts) {
      if (account.type === user.activeAccountType && account.providerId === user.activeProviderId) {
        return account;
      }
    }
    return undefined;
  }

  private extractActiveProvider(
    providers: Provider[],
    user: User,
  ): Provider | undefined {
    for (const provider of providers) {
      if (provider.id === user.activeProviderId) {
        return provider;
      }
    }
    return undefined;
  }

  public async activateUserAccount(
    providerId: string,
    accountType: AccountType,
    inviteCode?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    await Promise.all([
      this.sessionUserSDK.activateUserAccount(providerId, accountType, inviteCode, managed),
      managed ? undefined : this.setActiveAccount({ type: accountType, providerId }),
    ]);
  }

  public clearInviteCode(): void {
    this.store.dispatch(RegistrationActions.clearInviteCodeData());
  }

  public async setActiveAccount(
    account: { type: AccountType, providerId: string } | Account,
  ): Promise<void> {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, true));
    const user = this.getStoredActiveUser();
    if (!user) {
      throw new Error('Unexpected state: no active user');
    }
    await Promise.all([
      this.sessionUserSDK.setActiveAccount(account.type, account.providerId),
      this.featureFlagController.retrieveFlags(account.providerId),
    ]);
    const accounts = this.getStoredAccounts();
    const providers = this.getStoredProviders();
    user.activeAccountType = account.type;
    user.activeProviderId = account.providerId;
    const activeAccount = this.extractActiveAccount(accounts, user);
    const activeProvider = this.extractActiveProvider(providers, user);
    this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.User, user));
    this.setStoredActiveAccountAndProvider(activeAccount, activeProvider);
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, false));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, false));
  }

  private state(): SessionUserState {
    return this.store.getState()[REDUCER_KEY];
  }

  public getStoredActiveUser(): User | undefined {
    const userJson = this.state().user.activeEntity;
    if (!userJson) {
      return undefined;
    }
    return UserFactory.build(userJson);
  }

  public setStoredActiveUser(user: User): void {
    this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.User, user));
  }

  public setStoredUserLoading(loading: boolean): void {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, loading));
  }

  public setStoredActivePerson(person: Person): void {
    this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.Person, person));
  }

  public setStoredPersonLoading(loading: boolean): void {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Person, loading));
  }

  public getStoredActiveAccount(): Account | undefined {
    const accountJson = this.state().account.activeEntity;
    if (!accountJson) {
      return undefined;
    }
    return AccountFactory.build(accountJson);
  }

  public setStoredActiveAccount(account: Account): void {
    this.store.dispatch(sessionUserActions.setActiveEntity(ActionKey.Account, account));
  }

  public getStoredAccounts(): Account[] {
    const accountJsons = this.state().account.listEntities;
    return accountJsons.map(accountJson => AccountFactory.build(accountJson));
  }

  public setStoredAccountLoading(loading: boolean): void {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, loading));
  }

  public getStoredProviders(): Provider[] {
    const p = this.getStoredProvidersMap();
    const k = Object.keys(p);
    return k.map(key => p[key]);
  }

  public getStoredActiveProvider(): Provider | undefined {
    const providerJson = this.state().provider.activeEntity;
    if (!providerJson) {
      return undefined;
    }
    return ProviderFactory.build(providerJson);
  }

  public getStoredProvidersMap(): Record<string, Provider> {
    const p = this.state().provider.mapEntities;
    const k = Object.keys(p);
    const providersMap: Record<string, Provider> = {};
    k.map(key => providersMap[key] = ProviderFactory.build(p[key]));
    return providersMap;
  }

  public async authenticateActionLink(refreshToken?: string): Promise<{
    actionLink: ClientActionLink,
    expiresAt: Date,
  }> {
    const res = await this.sessionUserSDK.authenticateActionLink(refreshToken);
    const actionLink = ActionLinkFactory.buildClient(
      res.clientActionLinkJson as ClientActionLinkJson,
    );
    const expiresAt = new Date(res.expiresAt);
    this.store.dispatch(CoreActions.setActionLink(
      actionLink,
      expiresAt,
    ));
    return {
      actionLink,
      expiresAt,
    };
  }

  public clearActionLinkToken(): void {
    this.sessionUserSDK.clearActionLinkToken();
    this.store.dispatch(CoreActions.clearActionLink());
  }

  public async impersonateUser(user: User): Promise<void> {
    try {
      await this.sessionUserSDK.createSnapshotImpersonator(user.uid);
      this.sessionUserSDK.impersonatedUid = user.uid;
      window.location.reload();
    } catch (e) {
      this.sessionUserSDK.clearImpersonatedUid();
      throw e;
    }
  }

  public async logout(): Promise<void> {
    if (
      this.sessionUserSDK.actionLinkToken ||
      this.sessionUserSDK.actionLinkRefreshToken ||
      window.location.pathname.startsWith('/link/')
    ) {
      this.clearActionLinkToken();
      window.location.replace('/login');
    } else if (this.sessionUserSDK.impersonatedUid) {
      this.sessionUserSDK.clearImpersonatedUid();
      this.sessionUserSDK.clearActionLinkToken();
      await this.sessionUserSDK.removeSnapshotImpersonator();
      window.location.reload();
    } else {
      this.firebaseUserController.logout();
    }
  }

  // @NOTE: this is to continually check the operation hours
  // if we reached a time that the clinic is no longer open on the user's machine
  // We could potentially utilize snapshots for something like this by listening to changes on the active provider
  // but that would essentially make the snapshot of the provider readable by all users as it would be too expensive
  // to have a snapshot per user associated with the provider.
  public async startPingRefreshAccounts(): Promise<void> {
    if (this.counter.hasTick) return;
    this.counter.startTick(async () => {
      this.refreshAccounts();
    }, 60000);
  }

  public async stopPingRefreshAccounts(): Promise<void> {
    this.counter.stopTick();
  }

  public async requestGoogleAPIConsentURI(googleAPIName:string): Promise<string> {
    return await this.sessionUserSDK.requestGoogleAPIConsentURI(googleAPIName);
  }

  public async revokeGoogleAPIAccess(googleAPIName:string): Promise<void> {
    await this.sessionUserSDK.revokeGoogleAPIAccess(googleAPIName);
  }

  public async confirmConsentToGoogleAPI(
    confirmationCode: string,
    googleAPIName: string,
  ): Promise<void> {
    await this.sessionUserSDK.confirmConsentToGoogleAPI(
      confirmationCode,
      googleAPIName,
    );
  }

  private buildCustomFormsMaps(
    customForms: ClientCustomForm[],
    signedCustomForms: ClientSignedCustomForm[],
  ): {
    customFormsMap: Record<string, ClientCustomForm>,
    signedCustomFormsMap: Record<string, ClientSignedCustomForm>,
  } {
    const customFormsMap: Record<string, ClientCustomForm> = {};
    customForms.map(form => {
      customFormsMap[form.root.id] = form;
    });
    const signedCustomFormsMap: Record<string, ClientSignedCustomForm> = {};
    signedCustomForms.map(form => {
      signedCustomFormsMap[form.root.customFormId] = form;
    });
    return { customFormsMap, signedCustomFormsMap };
  }

  public async getMyCustomForms(providerId?: string, managed?: ManagedProperty): Promise<{
    customFormsMap: Record<string, ClientCustomForm>,
    signedCustomFormsMap: Record<string, ClientSignedCustomForm>,
  }> {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.CustomForms, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.SignedCustomForms, true));
    const res = await this.sessionUserSDK.getMyCustomForms(providerId, managed);
    if (managed) {
      this.store.dispatch(sessionUserActions.setLoading(ActionKey.CustomForms, false));
      this.store.dispatch(sessionUserActions.setLoading(ActionKey.SignedCustomForms, false));
      return this.buildCustomFormsMaps(res.customForms, res.signedCustomForms);
    }
    // @NOTE: if we are not managing a user, we only want the forms that are applicable
    // to the active account of the user (same provider and account type).
    const user = this.getStoredActiveUser();
    let accountCustomForms = [...res.customForms];
    if (user) {
      accountCustomForms = res.customForms.filter(
        f => f.root.applicableGroup?.applicableTo?.accountTypes.includes(user.activeAccountType),
      );
    }
    this.store.dispatch(sessionUserActions.setListEntities(
      ActionKey.CustomForms, accountCustomForms,
    ));
    this.store.dispatch(sessionUserActions.setListEntities(
      ActionKey.SignedCustomForms, res.signedCustomForms,
    ));
    this.store.dispatch(sessionUserActions.clearMapEntities(ActionKey.CustomForms));
    this.store.dispatch(sessionUserActions.clearMapEntities(ActionKey.SignedCustomForms));
    accountCustomForms.map(form => {
      this.store.dispatch(
        sessionUserActions.setMapEntity(ActionKey.CustomForms, form.root.id, form),
      );
    });
    res.signedCustomForms.map(form => {
      this.store.dispatch(
        sessionUserActions.setMapEntity(ActionKey.SignedCustomForms, form.root.customFormId, form),
      );
    });
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.CustomForms, false));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.SignedCustomForms, false));
    return this.buildCustomFormsMaps(accountCustomForms, res.signedCustomForms);
  }

  public async signCustomForm(
    customFormId: string,
    acceptedBy: string,
    body: string,
    coAcceptedBy?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sessionUserSDK.signCustomForm(
      customFormId,
      acceptedBy,
      body,
      coAcceptedBy,
      managed,
    );
  }
}
