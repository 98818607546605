import {
  AccountType,
  Provider,
} from '@pocketrn/entities/dist/core';
import { SubscriptionOptions, TechnicianSDK } from '../../services/firebase/TechnicianSDK';
import { ActionKey, technicianActions } from '../redux/sdk/actions';
import { PRNErrorResponse } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class ProviderController {
  public sdk: TechnicianSDK;
  public store: ReduxStore;

  constructor(sdk: TechnicianSDK, store: ReduxStore) {
    this.sdk = sdk;
    this.store = store;
  }

  public async retrieveProvider(id: string): Promise<Provider | undefined> {
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, true));
    const provider = await this.sdk.getProvider(id);
    if (!provider) {
      this.store.dispatch(technicianActions.unsetMapEntity(ActionKey.Provider, id));
    } else {
      this.store.dispatch(technicianActions.setMapEntity(ActionKey.Provider, id, provider));
    }
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, false));
    return provider;
  }

  public async retrieveProviders(): Promise<Provider[]> {
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, true));
    const allProviders = [];
    let iterations = 0;
    const MAX_ITERATIONS = 10;
    let res: { providers: Provider[]; nextPageCursorId: string | undefined } = {
      providers: [],
      nextPageCursorId: 'PRIMER',
    };
    while (res.nextPageCursorId && iterations < MAX_ITERATIONS) {
      iterations++;
      res = await this.sdk.getProviders(
        res.nextPageCursorId === 'PRIMER' ? undefined : res.nextPageCursorId,
      );
      allProviders.push(...res.providers);
      this.store.dispatch(technicianActions.setListEntities(ActionKey.Provider, allProviders));
      res.providers.map(provider => this.store.dispatch(
        technicianActions.setMapEntity(ActionKey.Provider, provider.id, provider),
      ));
    }
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, false));
    return allProviders;
  }

  public async checkProviderNameExists(name: string): Promise<boolean> {
    return await this.sdk.checkProviderNameExists(name);
  }

  public async addProvider(id: string, name: string): Promise<void> {
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, true));
    const provider = await this.sdk.addProvider(id, name);
    this.store.dispatch(technicianActions.setMapEntity(ActionKey.Provider, id, provider));
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, false));
  }

  public async updateProvider(
    provider: Provider,
    options?: {
      updateExistingPatientsTrial?: boolean,
      updateExistingPatientsDiscount?: boolean,
    },
  ): Promise<void> {
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, true));
    await this.sdk.updateProvider(provider, options);
    this.store.dispatch(technicianActions.setMapEntity(ActionKey.Provider, provider.id, provider));
    this.store.dispatch(technicianActions.setLoading(ActionKey.Provider, false));
  }

  public async inviteUserToAccount(
    email: string,
    type: AccountType,
    providerId: string | undefined,
    noEmail: boolean,
    subscriptionOptions?: SubscriptionOptions,
  ): Promise<void> {
    await this.sdk.inviteUserToAccount(
      type,
      email,
      providerId,
      noEmail,
      subscriptionOptions,
    );
  }

  public async generateInviteCode(providerId: string): Promise<string> {
    const inviteCode = await this.sdk.generateInviteCode(providerId);
    await this.retrieveProviders();
    return inviteCode;
  }

  public async setInviteCode(
    providerId: string,
    inviteCode: string | null,
  ): Promise<string | undefined> {
    try {
      await this.sdk.setInviteCode(providerId, inviteCode);
      await this.retrieveProviders();
    } catch (err) {
      if (err instanceof PRNErrorResponse && err.code === 'INVITE_CODE_CONFLICT') {
        return err.data;
      } else {
        throw err;
      }
    }
  }
}
