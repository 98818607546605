import { AccountType, ClientCustomForm, ClientSignedCustomForm, isAcceptor, isRequestor, User } from '@pocketrn/entities/dist/core';
import { Person } from '@pocketrn/entities/dist/community';
import { LocalePrimer } from '@pocketrn/localizer';
import { TimeUtils } from '@pocketrn/time-utils';
import { logger } from '@pocketrn/client/dist/app-logger';

interface CustomFormsParams {
  customFormsMap: Record<string, ClientCustomForm>;
  signedCustomFormsMap: Record<string, ClientSignedCustomForm>;
  filters?: { providerId?: string, includeOptionalForms?: boolean };
}

export enum AcknowledgmentType {
  PrivacyPractices = 'privacyPractices',
  VisitConsent = 'visitConsent',
  TermsOfService = 'termsOfService',
  CaregiverAuthorization = 'caregiverAuthorization',
}

export const ACCEPTOR_TERMS = [AcknowledgmentType.TermsOfService];
export const REQUESTOR_TERMS = [
  AcknowledgmentType.VisitConsent,
  // @NOTE: We only use the Visit Consent anymore since the Privacy Practices and Terms of Service
  // are now rolled up into the Visit Consent.
  // AcknowledgmentType.PrivacyPractices,
  // AcknowledgmentType.TermsOfService,
];
export const TECHNICIAN_TERMS = [AcknowledgmentType.TermsOfService];
export const CARE_ADMIN_TERMS = [AcknowledgmentType.TermsOfService];
export const REQUIRE_SIGN_TERMS = [
  // @NOTE: We only use the Visit Consent anymore since the Privacy Practices and Terms of Service
  // are now rolled up into the Visit Consent.
  // AcknowledgmentType.PrivacyPractices,
  // AcknowledgmentType.TermsOfService,
  AcknowledgmentType.VisitConsent,
];
export const CAREGIVER_FULL_AUTH_REQUESTOR_TERMS = [
  ...REQUESTOR_TERMS,
  // @NOTE: The new patient flow just doesn't make sense to have this.
  // The caregiver is the one inviting the patient and they are the ones signing this if they
  // are PoA, not the patient.  It's just provides no value from what was already set on the
  // permissions.
  // AcknowledgmentType.CaregiverAuthorization,
];
export const NO_REQUIRE_SIGN_TERMS = Object.values(AcknowledgmentType).filter(
  t => !REQUIRE_SIGN_TERMS.includes(t),
);
const MAXIMUM_YEARS_TERM_LEGALITY = 3;

export const uniqueAndValid = (terms: AcknowledgmentType[]): AcknowledgmentType[] => {
  const arr = Array.from(new Set(terms));
  const validTerms = Object.values(AcknowledgmentType);
  return arr.filter(t => validTerms.includes(t));
};

export const getTerms = (
  user: User | undefined,
  caregiverHasFullAuth?: boolean,
): AcknowledgmentType[] => {
  if (!user) {
    logger.logError('user is undefined when attempting to getTerms');
    return REQUESTOR_TERMS;
  }
  const acks = user.acknowledgments?.acknowledgments;
  const usersTerms = Object.keys(acks ?? {}) as AcknowledgmentType[];
  // @NOTE: We will filter the terms based on the user's account type.
  // And this is because some users have multiple account types.
  if (isRequestor(user.activeAccountType)) {
    if (caregiverHasFullAuth) {
      const userTermsAsFullAccessCaregiver = usersTerms.filter(
        t => CAREGIVER_FULL_AUTH_REQUESTOR_TERMS.includes(t),
      );
      return uniqueAndValid([
        ...CAREGIVER_FULL_AUTH_REQUESTOR_TERMS,
        ...userTermsAsFullAccessCaregiver,
      ]);
    }
    const userTermsAsRequestor = usersTerms.filter(t => REQUESTOR_TERMS.includes(t));
    return uniqueAndValid([ ...REQUESTOR_TERMS, ...userTermsAsRequestor ]);
  } else if (isAcceptor(user.activeAccountType)) {
    const userTermsAsAcceptor = usersTerms.filter(t => ACCEPTOR_TERMS.includes(t));
    return uniqueAndValid([ ...ACCEPTOR_TERMS, ...userTermsAsAcceptor ]);
  } else if (user.activeAccountType === AccountType.Technician) {
    const userTermsAsTechnician = usersTerms.filter(t => TECHNICIAN_TERMS.includes(t));
    return uniqueAndValid([ ...TECHNICIAN_TERMS, ...userTermsAsTechnician ]);
  } else if (user.activeAccountType === AccountType.CareAdmin) {
    const userTermsAsCareAdmin = usersTerms.filter(t => CARE_ADMIN_TERMS.includes(t));
    return uniqueAndValid([ ...CARE_ADMIN_TERMS, ...userTermsAsCareAdmin ]);
  } else {
    throw new Error(`unknown account type: ${user.activeAccountType}`);
  }
};

export const useAcknowledgmentsState = (primer: LocalePrimer): (
  {
    anyAcknowledgmentSigned: (user: User | undefined) => boolean,

    /**
     * @param accountType - If known, the account type of the user should be included.
     * This information is crucial in specific scenarios, such as when caregivers select patients.
     * In these cases, relying solely on the patient user's user.activeAccountType is insufficient
     * due to potential multiple accounts and varying active account usage.
     * This can result in missing terms for the caregiver."
     */
    allAcknowledgmentsSigned: (
      user: User | undefined,
      person: Person | undefined,
      accountType: AccountType | undefined,
      accountManagedByCaregiver: boolean,
      customForms?: CustomFormsParams,
    ) => boolean | undefined,
    getLegalName: (person: Person | undefined) => string | undefined,
    isAcknowledgmentSigned: (
      user: User,
      person: Person,
      acknowledgmentId: string,
    ) => boolean,
    isAllCustomFormsSigned: (
      person: Person,
      customForms: CustomFormsParams,
    ) => boolean,
  }
) => {
  const isAllCustomFormsSigned = (
    person: Person,
    customForms: CustomFormsParams,
  ) => {
    const legalName = getLegalName(person);
    if (!legalName) {
      return false;
    }
    let { customFormsMap } = customForms;
    const { signedCustomFormsMap, filters } = customForms;
    if (filters?.providerId) {
      const filtered = Object.values(customFormsMap).filter(
        f => f.providerId === filters.providerId,
      );
      if (!filtered.length) {
        return true;
      }
      customFormsMap = filtered.reduce((acc, form) => {
        acc[form.root.id] = form;
        return acc;
      }, {} as Record<string, ClientCustomForm>);
    }
    const requiredCustomForms = filters?.includeOptionalForms ?
      Object.values(customFormsMap) :
      Object.values(customFormsMap).filter(f => f.root.required);
    if (requiredCustomForms.length === 0) return true;
    for (const form of requiredCustomForms) {
      const signedForm = signedCustomFormsMap[form.root.id];
      if (!signedForm) {
        return false;
      }
      if (!signedForm.isValid(legalName, form.root.version)) {
        return false;
      }
    }
    return true;
  };

  const anyAcknowledgmentSigned = (
    user: User | undefined,
  ): boolean => {
    if (!user) {
      return false;
    }
    const keys = Object.keys(user.acknowledgments?.acknowledgments ?? {});
    if (!keys.length) {
      return false;
    }
    return !!user.acknowledgments?.acknowledgments?.[keys[0]]?.acceptedBy;
  };

  const allAcknowledgmentsSigned = (
    user: User | undefined,
    person: Person | undefined,
    accountType: AccountType | undefined,
    accountManagedByCaregiver: boolean,
    customForms?: CustomFormsParams,
  ): boolean | undefined => {
    if (!user || !person) {
      return false;
    }
    const terms = getRequiredAcknowledgments(
      accountType ?? user.activeAccountType,
      accountManagedByCaregiver,
    );
    for (const term of terms) {
      if (!isAcknowledgmentSigned(user, person, term)) {
        return false;
      }
    }
    if (customForms) {
      if (!isAllCustomFormsSigned(person, customForms)) {
        return false;
      }
    }
    return true;
  };

  const getLegalName = (person: Person | undefined): string | undefined => {
    return person?.fullLegalName(primer);
  };

  const hasOutdatedSignature = (
    user: User,
    acknowledgmentId: string,
  ): boolean => {
    const acknowledgmentLastUpdatedAt = new Date(primer.translate(
      'core.acknowledgments.terms', `${acknowledgmentId}.updatedAt`,
    ));
    const lastAcceptedAt = user.acknowledgments?.acknowledgments[acknowledgmentId].acceptedAt;
    if (!lastAcceptedAt) {
      return true;
    }
    const { years } = TimeUtils.getTimeSince(lastAcceptedAt, new Date());
    if (years >= MAXIMUM_YEARS_TERM_LEGALITY) {
      return true;
    }
    return lastAcceptedAt < acknowledgmentLastUpdatedAt;
  };

  const signatureDifferentThanLegalName = (
    user: User,
    person: Person,
    acknowledgmentId: string,
  ):boolean => {
    const acceptedBy = user.acknowledgments?.acknowledgments[acknowledgmentId].acceptedBy;
    if (!acceptedBy) {
      return true;
    }
    return getLegalName(person) !== acceptedBy;
  };

  const isAcknowledgmentSigned = (
    user: User,
    person: Person,
    acknowledgmentId: string,
  ): boolean => {
    const acknowledgment = user.acknowledgments?.acknowledgments[acknowledgmentId];
    if (!acknowledgment) {
      return false;
    }
    if (hasOutdatedSignature(user, acknowledgmentId)) {
      return false;
    }
    if (signatureDifferentThanLegalName(user, person, acknowledgmentId)) {
      return false;
    }
    return true;
  };

  const getRequiredAcknowledgments = (
    accountType: AccountType,
    accountManagedByCaregiver: boolean,
  ): string[] => {
    if (accountManagedByCaregiver) {
      return CAREGIVER_FULL_AUTH_REQUESTOR_TERMS;
    } else if (isRequestor(accountType)) {
      return REQUESTOR_TERMS;
    } else if (isAcceptor(accountType)) {
      return ACCEPTOR_TERMS;
    } else if (accountType === AccountType.Technician) {
      return TECHNICIAN_TERMS;
    } else if (accountType === AccountType.CareAdmin) {
      return CARE_ADMIN_TERMS;
    } else {
      throw new Error(`unknown account type: ${accountType}`);
    }
  };

  return {
    anyAcknowledgmentSigned,
    allAcknowledgmentsSigned,
    getLegalName,
    isAcknowledgmentSigned,
    isAllCustomFormsSigned,
  };
};
