import React, { Fragment, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import localePrimer from '../../../utils/localePrimer';
import {
  Button,
  ButtonGroup,
  Group,
  H2,
  Markdown,
  Modal,
  ModalFooter,
  ModalHeader,
  Task,
} from '@pocketrn/rn-designsystem';
import { Acknowledgment, Acknowledgments, ClientCustomForm, ClientSignedCustomForm, User } from '@pocketrn/entities/dist/core';
import { Person } from '@pocketrn/entities/dist/community';
import Signature from '../Signature';
import {
  AcknowledgmentType,
  NO_REQUIRE_SIGN_TERMS,
  REQUIRE_SIGN_TERMS,
  getTerms,
  useAcknowledgmentsState,
} from '../../../utils/useAcknowledgmentsState';
import { profileController, userController } from '../../../state/controllers';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '../../../apps/user-state';
import { getManagedProperty } from '../../../utils/managedProperty';
import UserCustomForms from './UserCustomForms.form';
import { ProfileContext } from '../../routes/profile/ProfileContext';
import { useFilteredCustomForms } from '../../../utils/useFilteredCustomForms';
import { selectActionLink } from '../../../state/redux/core/selectors';

const locale = 'core.acknowledgments';

export function addSignedAcknowledgmentToUser(
  user: User,
  acknowledgment: Acknowledgment,
): User {
  const _user = user.copy();
  if (!_user.acknowledgments) {
    _user.acknowledgments = new Acknowledgments({});
  }
  _user.acknowledgments = new Acknowledgments({
    ..._user.acknowledgments.acknowledgments,
    [acknowledgment.id]: acknowledgment,
  });
  return _user;
}

interface Props {
  user: User;
  person: Person;
  coSigner?: Person;
  saveType?: 'save' | 'next' | 'done';
  backType?: 'back' | 'cancel';
  accountManagedByCaregiver?: boolean;
  skipIfAllRequiredSigned?: boolean;
  mustConsentToContinue?: boolean;
  showSendActionLinks?: boolean;
  customForms?: {
    customFormsMap: Record<string, ClientCustomForm>,
    signedCustomFormsMap: Record<string, ClientSignedCustomForm>,
  },
  onSigned?: (acknowledgment: Acknowledgment, newUser: User) => void;
  onNameChange?: (firstName: string, lastName: string, newPerson: Person) => void;
  onCoSignerNameChange?: (firstName: string, lastName: string, newCoSigner: Person) => void;
  onAllSigned?: () => void;
  onSendActionLinks?: () => void;
  onSave?: () => void;
  onBack?: () => void;

}

const AcknowledgmentsForm:FunctionComponent<Props> = (props): ReactElement => {
  const sessionUser = useSelector(selectSessionUser);
  const ctx = useContext(ProfileContext);
  const {
    user,
    person,
    coSigner,
    accountManagedByCaregiver,
    skipIfAllRequiredSigned,
    showSendActionLinks,
    mustConsentToContinue,
  } = props;

  const [ showModal, setShowModal ] = useState(false);
  const [ modalKey, setModalKey ] = useState(undefined as AcknowledgmentType | undefined);
  const [ allSigned, setAllSigned ] = useState(false);
  const {
    allAcknowledgmentsSigned,
    getLegalName,
    isAcknowledgmentSigned,
  } = useAcknowledgmentsState(localePrimer);
  const [ loadingConsent, setLoadingConsent ] = useState<AcknowledgmentType | undefined>(undefined);
  const [ loadingNameChange, setLoadingNameChange ] = useState(false);
  const [ terms, setTerms ] = useState([] as AcknowledgmentType[]);
  const actionLink = useSelector(selectActionLink);
  const filteredCustomFormsMap = useFilteredCustomForms(mustConsentToContinue);
  const shouldHideAlreadySigned = !!actionLink || mustConsentToContinue;
  const [ hadToSignTerms, setHadToSignTerms ] = useState(false);
  const providedCustomForms = props.customForms;
  const sessionUserSigningOwnTerms = !ctx.managedUid;
  const patientIsAvailableToSign = !!(accountManagedByCaregiver === false &&
    !coSigner && ctx.managedUid);
  const poaIsAvailableToSign = (accountManagedByCaregiver === true && ctx.managedUid) &&
    (ctx.linkedUser?.root.permissions?.powerOfAttorney || coSigner?.uid !== sessionUser?.uid);
  const isDesignatedSinger = !!(sessionUserSigningOwnTerms ||
    patientIsAvailableToSign ||
    poaIsAvailableToSign);

  useEffect(() => {
    if (skipIfAllRequiredSigned) return;
    const customForms = {
      customFormsMap: providedCustomForms?.customFormsMap ?? filteredCustomFormsMap,
      signedCustomFormsMap: providedCustomForms?.signedCustomFormsMap ?? ctx.signedCustomFormsMap,
      filters: { includeOptionalForms: !!actionLink || !mustConsentToContinue },
    };
    const _allSigned = !!allAcknowledgmentsSigned(
      user,
      person,
      undefined,
      accountManagedByCaregiver ?? false,
      customForms,
    );
    // @NOTE: if the user to manually sign terms, we want the to click on the next action
    // provided by the parent component and not automatically go to the next step.
    // Whereas in action links, the behavior is different, if all required terms are signed already,
    // we automatically call onAllSigned and complete the action link.
    if (props.onAllSigned && _allSigned && !hadToSignTerms) {
      props.onAllSigned();
    }
    setAllSigned(_allSigned);
  }, [
    user,
    ctx.customFormsMap,
    ctx.signedCustomFormsMap,
    providedCustomForms,
    skipIfAllRequiredSigned,
  ]);

  useEffect(() => {
    getUserTerms();
  }, [ ]);

  const getUserTerms = () => {
    const terms: AcknowledgmentType[] = [];
    getTerms(user, accountManagedByCaregiver).forEach(term => {
      const isSigned = isAcknowledgmentSigned(user, person, term);
      // @NOTE: if already signed, we don't show the term for action links for ack modal
      if (isSigned && shouldHideAlreadySigned) {
        return;
      }
      terms.push(term);
    });
    setTerms(terms);
  };

  const toggleModal = (key: AcknowledgmentType) => {
    setModalKey(key);
    setShowModal(true);
  };

  const signAcknowledgment = (id: AcknowledgmentType | undefined, ignoreModalClose?: boolean) => {
    _signAcknowledgment(id, ignoreModalClose);
  };

  const _signAcknowledgment = async (
    id: AcknowledgmentType | undefined,
    ignoreModalClose?: boolean,
  ) => {
    if (!props.onSigned) {
      throw new Error('cannot sign because onAllSigned is not set');
    }
    if (!id) {
      throw new Error('id is undefined');
    }
    const legalName = getLegalName(person);
    if (!legalName) {
      throw new Error('person does not have fullLegalName');
    }
    const legalNameCoSigner = getLegalName(coSigner);
    const acknowledgment = new Acknowledgment(id, new Date(), legalName, legalNameCoSigner);
    const newUser = user.copy();
    if (!newUser.acknowledgments) {
      newUser.acknowledgments = new Acknowledgments({});
    }
    newUser.acknowledgments.acknowledgments[id] = acknowledgment;
    setLoadingConsent(id);
    setHadToSignTerms(true);
    await userController.acceptUserAcknowledgment(
      acknowledgment.id,
      acknowledgment.acceptedAt,
      acknowledgment.acceptedBy,
      acknowledgment.coAcceptedBy || undefined,
      sessionUser?.uid === user.uid ? undefined : getManagedProperty(user.uid),
    );
    setLoadingConsent(undefined);
    props.onSigned(acknowledgment, newUser);
    if (!ignoreModalClose) {
      setShowModal(false);
    }
  };

  const getFooterContent = () => {
    const isSigned = isAcknowledgmentSigned(user, person, modalKey as string);
    const buttons = [];
    if (!isSigned && props.onSigned) {
      buttons.push(
        <Button
          key={0}
          type="primary"
          onClick={() => signAcknowledgment(modalKey)}
          testIdRoot="acknowledgments-modalFooter-accept-btn"
        >
          {localePrimer.translate(locale, 'signature.acceptBtn')}
        </Button>,
        <Button
          key={1}
          type="text"
          onClick={() => setShowModal(false)}
          testIdRoot="acknowledgments-modalFooter-cancel-btn"
        >
          {localePrimer.translate('shared.cancel')}
        </Button>,
      );
    } else {
      buttons.push(
        <Button
          key={0}
          type="secondary"
          onClick={() => setShowModal(false)}
          testIdRoot="acknowledgments-modalFooter-cancel-btn"
        >
          {localePrimer.translate('shared.done')}
        </Button>,
      );
    }
    return <ModalFooter buttons={buttons} />;
  };

  const handleToggle = (id: AcknowledgmentType) => {
    const isSigned = isAcknowledgmentSigned(user, person, id);
    const shouldToggleAck = REQUIRE_SIGN_TERMS.includes(id) || !props.onSigned || isSigned;
    if (shouldToggleAck) {
      toggleModal(id);
      return;
    }
    if (isDesignatedSinger) {
      signAcknowledgment(id, true);
      return;
    }
    toggleModal(id);
  };

  const getTask = (id: AcknowledgmentType): ReactElement => {
    return (
      <Task
        key={id}
        active={false}
        complete={isAcknowledgmentSigned(user, person, id)}
        onToggle={() => handleToggle(id)}
        localePrimer={localePrimer}
        label={localePrimer.translate(locale, 'terms', id, 'title')}
        onViewTextClick={() => toggleModal(id)}
        testIdRoot={`acknowledgments-task-${id}`}
        loading={loadingConsent === id}
      />
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleOnNameChange = (firstName: string, lastName: string, isCoSigner: boolean) => {
    _handleOnNameChange(firstName, lastName, isCoSigner);
  };

  const _handleOnNameChange = async (firstName: string, lastName: string, isCoSigner: boolean) => {
    if (!props.onNameChange) {
      throw new Error('cannot change name because onNameChange is not set');
    }
    if (isCoSigner) {
      if (!coSigner || !props.onCoSignerNameChange) {
        return;
      }
      const newCoSigner = coSigner.copy();
      newCoSigner.firstName = firstName;
      newCoSigner.lastName = lastName;
      props.onCoSignerNameChange(firstName, lastName, newCoSigner);
    } else {
      const newPerson = person.copy();
      newPerson.firstName = firstName;
      newPerson.lastName = lastName;
      setLoadingNameChange(true);
      await profileController.updateProfile(
        newPerson,
        sessionUser?.uid === user.uid ? undefined : getManagedProperty(user.uid),
      );
      setLoadingNameChange(false);
      props.onNameChange(firstName, lastName, newPerson);
    }
  };

  const getAcknowledgment = (id: AcknowledgmentType): Acknowledgment | undefined => {
    if (!user.acknowledgments?.acknowledgments) {
      return undefined;
    }
    return user.acknowledgments.acknowledgments[id];
  };

  const getCoSignatureMode = (id: AcknowledgmentType) => {
    const isSigned = isAcknowledgmentSigned(user, person, id);
    if (sessionUserSigningOwnTerms && !isSigned) {
      return undefined;
    }
    if (isSigned && !user.acknowledgments?.acknowledgments[id].coAcceptedBy) {
      return undefined;
    }
    if (isSigned) {
      return 'read-only';
    }
    if (poaIsAvailableToSign && !isSigned) {
      return 'new-signature';
    }
  };

  return (
    <Fragment>
      {terms.length > 0 && (
        <Group
          title={localePrimer.translate(locale, 'forPRN')}
          testIdRoot="procketRN-acknowledgments-group`"
        >
          {terms.map(term => getTask(term))}
        </Group>
      )}
      <UserCustomForms
        user={user}
        person={person}
        localePrimer={localePrimer}
        coSigner={coSigner}
        onNameChange={props.onNameChange ? handleOnNameChange : undefined}
        mustConsentToContinue={props.mustConsentToContinue}
        isDesignatedSinger={isDesignatedSinger}
      />
      <ButtonGroup direction="column">
        {showSendActionLinks &&
          <Button
            type={!allSigned ? 'primary' : 'secondary'}
            onClick={props.onSendActionLinks}
            loading={!!loadingConsent}
            testIdRoot="userCustomForm-sendLinks-btn"
          >
            {localePrimer.translate(locale, 'getConsentsSinged')}
          </Button>
        }
        {!!props.saveType &&
          <Button
            type="primary"
            onClick={props.onSave}
            disabled={!allSigned}
            loading={!!loadingConsent}
            testIdRoot="acknowledgments-save-btn"
            submitOnEnter
          >
            {localePrimer.translate('shared', props.saveType)}
          </Button>
        }
        {!!props.backType &&
          <Button
            type="text"
            onClick={props.onBack}
            testIdRoot="acknowledgments-back-btn"
          >
            {localePrimer.translate('shared', props.backType)}
          </Button>
        }
      </ButtonGroup>
      {showModal && (
        <Modal
          onClose={closeModal}
          hide={!showModal}
          fullScreen
          header={
            <ModalHeader
              title={localePrimer.translate(locale, 'overline')}
              onClose={
                !isDesignatedSinger ?
                  closeModal :
                  modalKey && NO_REQUIRE_SIGN_TERMS.includes(modalKey) ? closeModal : undefined
              }
            />
          }
          footer={
            modalKey && NO_REQUIRE_SIGN_TERMS.includes(modalKey) ?
              getFooterContent()
              : undefined
          }
          loading={!!(loadingConsent || loadingNameChange)}
        >
          <H2
            align="center"
            text={localePrimer.translate(locale, 'terms', `${modalKey}.title`)}
          />
          <Markdown
            size="sm"
            markdown={localePrimer.translate(locale, 'terms', `${modalKey}.content`)}
          />
          {(modalKey && REQUIRE_SIGN_TERMS.includes(modalKey)) &&
            <Signature
              loading={!!(loadingConsent || loadingNameChange)}
              user={user}
              person={person}
              onNameChange={props.onSigned ? handleOnNameChange : undefined}
              onAccept={props.onSigned ?
                () => signAcknowledgment(modalKey) :
                undefined}
              onCancel={() => setShowModal(false)}
              coSigner={coSigner}
              acknowledgment={getAcknowledgment(modalKey)}
              coSignatureMode={getCoSignatureMode(modalKey)}
            />
          }
        </Modal>
      )}
    </Fragment>
  );
};

export default AcknowledgmentsForm;
