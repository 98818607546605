import { User } from '@pocketrn/entities/dist/core';
import { TimeUtils } from '@pocketrn/time-utils';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '../apps/user-state';

const getSystemTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const useUserTimeZone = (options?: {
  user?: User,
  showTz?: boolean,
}):
{
  timeZone: string;
  showTimeZone: boolean;
} => {
  const sessionUser = useSelector(selectSessionUser);
  const _user = options?.user ?? sessionUser;
  if (!_user) {
    return {
      timeZone: getSystemTimeZone(),
      showTimeZone: true,
    };
  }
  const userTimeZone = _user.getTimezoneOrDefault({ getFromBrowser: true });
  return {
    timeZone: TimeUtils.getIntlTimeZone(userTimeZone),
    showTimeZone: options?.showTz || false,
  };
};
