import { LocalePrimer } from '@pocketrn/localizer';
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './ChatTextDate.module.css';
import { useUserTimeZone } from '../../../../../utils/useUserTimeZone';

interface ChatTextDateProps {
  date: Date;
  localePrimer: LocalePrimer;
}

export const ChatTextDate: FunctionComponent<ChatTextDateProps> = (props): ReactElement => {
  const userTimeZoneOptions = useUserTimeZone();

  return (
    <div className={styles.date}>
      {props.localePrimer.translate('format.date.onlyDate')(
        props.date,
        userTimeZoneOptions.showTimeZone,
      )}
    </div>
  );
};
