import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  Children,
  Fragment,
  ReactNode,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteProps } from '../../entities/RouteProps';
import {
  selectProviderLoading,
  selectProviderMap,
} from '../../state/redux/sdk/selectors';
import { AccountType, Provider } from '@pocketrn/entities/dist/core';
import { assertNotFragment, Snackbar } from '@pocketrn/rn-designsystem';
import { selectSessionUser } from '../../../user-state';

interface Props extends RouteProps {
  children?: ReactNode;
}

const locale = 'technician.manageProvider';

const ManageProviderWrapper: FunctionComponent<Props> = (props): ReactElement => {
  const { id } = useParams();
  const providerLoading = useSelector(selectProviderLoading);
  const providerId = id;
  const [ provider, setProvider ] = useState({} as Provider | undefined);
  const [ snackbarMessage, setSnackbarMessage ] = useState('');
  const providerMap = useSelector(selectProviderMap);
  const sessionUser = useSelector(selectSessionUser);
  const isTechnician = sessionUser?.activeAccountType === AccountType.Technician;

  const retrieveProvider = async () => {
    if (providerId && provider?.id !== providerId) {
      const cachedProviderMap = providerMap && providerMap[providerId];
      if (cachedProviderMap) {
        setProvider(cachedProviderMap);
      } else {
        const provider = await props.providerController.retrieveProvider(providerId);
        if (provider) {
          setProvider(provider);
        } else {
          setSnackbarMessage(props.localePrimer.translate(locale, 'cannotFindProvider'));
        }
      }
    }
  };

  useEffect(() => {
    retrieveProvider();
  }, [providerId]);

  return (
    <Fragment>
      {provider?.id && Children.map(props.children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        assertNotFragment(child);

        return (
          React.cloneElement((child as ReactElement), {
            ...props,
            provider,
            isTechnician,
            loading: providerLoading,
          })
        );
      })}
      <Snackbar
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage('')}
      />
    </Fragment>
  );
};

export default ManageProviderWrapper;
