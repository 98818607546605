import { Person, PersonJson, PersonFactory } from '@pocketrn/entities/dist/community';
import { AccountType, Region, UserFactory, UserJson, User, Provider, ProviderFactory, ProviderJson } from '@pocketrn/entities/dist/core';
import {
  Meeting,
  MeetingFactory,
  QueuedAcceptorFactory,
  QueuedRequestorFactory,
  QueueStatsFactory,
  ClientParticipant,
  Shift,
  ShiftJson,
  ShiftFactory,
  ShiftType,
  SingleShiftJson,
  SingleShiftFactory,
  SingleShift,
  ScheduledMeeting,
  ScheduledMeetingJson,
  ScheduledMeetingStatus,
  ScheduledMeetingFactory,
  MeetingJson,
  MultiSelectResponse,
  ClientCustomNotified,
  ClientCallType,
  ClientCustomCallType,
  ClientCustomCallTypeJson,
  CallTypeFactory,
  PatientNoShowAction,
} from '@pocketrn/entities/dist/meeting';
import { Auth } from 'firebase/auth';
import { StateAbbreviation } from '@pocketrn/localizer';
import { TimeZone } from '@pocketrn/time-utils';
import { MatchMakingResponse, UserMatch, buildUserMatch } from '../../../../utils/userMatchHelper';
import { FirebaseSDK, FirebaseFunctionInterface, OnCallFunction, ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { CursorId } from '@pocketrn/rn-designsystem';

interface ShiftData {
  providerIds: string[];
  type: ShiftType;
  accountType: AccountType;
  startAt: string,
  endAt: string,
  timeZone: string,
  id? : string,
};

export interface AvailableNurseTimes {
  person: Person,
  availableTimes: Date[],
}

export class MeetingSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async setAcceptedCallTypes(acceptedCallTypes: string[]): Promise<void> {
    try {
      const data: any = { acceptedCallTypes };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'setAcceptedCallTypes')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setIncludeInQueue(providerIdsToInclude: (string | undefined)[]): Promise<void> {
    try {
      const data: any = { providerIdsToInclude: providerIdsToInclude.map(p => p ?? null) };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'setIncludeInQueue')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async sendMeetingFeedback(
    meetingId: string,
    feedbackValues: Record<string, number | MultiSelectResponse | null | string>,
  ): Promise<void> {
    try {
      const data: any = { meetingId, feedbackValues };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'sendMeetingFeedback')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async checkMatchmaking(managed?: ManagedProperty): Promise<MatchMakingResponse> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'checkMatchmaking',
        managed,
      )({});
      const { userMatch, queuedAcceptor, queuedRequestor, queueStats } = resp.data;
      return {
        userMatch: userMatch ? buildUserMatch(userMatch) : {
          usersMap: {},
          personsMap: {},
          meetings: [],
          providersMap: {},
          customCallTypesMap: {},
        },
        queuedAcceptor: queuedAcceptor ? QueuedAcceptorFactory.build(queuedAcceptor) : undefined,
        queuedRequestor: queuedRequestor ?
          QueuedRequestorFactory.build(queuedRequestor) : undefined,
        queueStats: queueStats ? QueueStatsFactory.build(queueStats) : undefined,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async requestUserMatch(
    providerId: string,
    requestedCallType: ClientCallType,
    region: string,
    requestorNote: string | null,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'startRequestorMatchmaking',
        managed,
      )({
        providerId,
        requestedCallType: requestedCallType.root.id,
        region,
        requestorNote,
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async stopUserMatch(): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'stopMatchmaking')({});
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async startMeeting(): Promise<UserMatch> {
    const resp = await this.ackFindUserMatch('start');
    if (!resp) {
      throw new Error('unexpected response from ackFindUserMatch with action == start');
    }
    return resp;
  }

  public async getZoomSessionToken(meetingId: string): Promise<string> {
    const data = { meetingId };
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Meeting, 'getZoomSessionToken')(data);
      return resp.data.token;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async acceptUserMatch(): Promise<void> {
    await this.ackFindUserMatch('accept');
  }

  public async declineUserMatch(): Promise<void> {
    try {
      await this.ackFindUserMatch('decline');
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  private async ackFindUserMatch(action: 'accept' | 'decline' | 'start'): Promise<UserMatch | void> {
    try {
      const data: any = { action };
      const resp = await this.functions.httpsCallable(OnCallFunction.Meeting, 'ackFindUserMatch')(data);
      if (action === 'start') {
        return buildUserMatch(resp.data.userMatch);
      }
      return;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setMeetingStats(meetingId: string, timeStatsInMinutes: {
    patientLate: number;
    preMeeting: number;
    meeting: number;
    postMeeting: number;
  }, patientNoShow: { action: PatientNoShowAction; context: string | null } | null): Promise<{
    supportTicketId: string | null;
  }> {
    try {
      const data: any = {
        meetingId,
        timeStatsInMinutes,
        patientNoShow,
      };
      const resp = await this.functions.httpsCallable(OnCallFunction.Meeting, 'setMeetingStats')(data);
      return {
        supportTicketId: resp.data.supportTicketId,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async endMeeting(meetingId: string): Promise<void> {
    try {
      const data: any = { meetingId };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'endMeeting')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async blockUser(blockUid: string, meetingId?: string): Promise<void> {
    try {
      const data: any = { blockUid };
      if (meetingId) {
        data.meetingId = meetingId;
      }
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'blockUser')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getShifts(): Promise<Shift[]> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Meeting, 'getShifts')({});
      return resp.data.shifts.map((shift: ShiftJson | SingleShiftJson) => {
        switch (shift.type) {
          case ShiftType.Single:
            return SingleShiftFactory.build(shift as SingleShiftJson);
          default:
            return ShiftFactory.build(shift);
        }
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async addShift(shift: Shift): Promise<void> {
    try {
      const data = { shift: this.buildShiftJson(shift) };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'addShift')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async editShift(shift: Shift): Promise<void> {
    try {
      const data = { shift: this.buildShiftJson(shift) };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'editShift')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  private buildShiftJson(shift: Shift): ShiftData {
    const singleShift = shift.copy() as SingleShift;
    return {
      providerIds: shift.providerIds,
      type: shift.type,
      accountType: shift.accountType,
      startAt: singleShift.startAt.toISOString(),
      endAt: singleShift.endAt.toISOString(),
      timeZone: singleShift.timeZone,
      id: shift.id.length > 0 ? shift.id : undefined,
    };
  }

  public async deleteShift(id: string): Promise<void> {
    try {
      const data = { id };
      const res = await this.functions.httpsCallable(OnCallFunction.Meeting, 'deleteShift')(data);
      if (res) {
        const { scheduledMeetingsIds } = res.data;
        return scheduledMeetingsIds;
      }
    } catch (err) {
      this.getShifts();
      throw await this.handleErr(err);
    }
  }

  public async getScheduledMeetings(options?: { limit?: number, managed?: ManagedProperty })
  : Promise<{
    scheduledMeetings: ScheduledMeeting[];
    usersMap: Record<string, User>;
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
  }> {
    try {
      const data: any = {};
      if (options?.limit) {
        data.limit = options.limit;
      }
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'getScheduledMeetings',
        options?.managed,
      )(data);
      const scheduledMeetings = resp.data.scheduledMeetings
        .map((scheduledMeeting: ScheduledMeetingJson) => {
          return ScheduledMeetingFactory.build(scheduledMeeting);
        });
      const usersMap = Object.fromEntries(Object.entries(resp.data.usersMap)
        .map(([ uid, user ]) => {
          return [ uid, UserFactory.build(user as UserJson) ];
        }));
      const personsMap = Object.fromEntries(Object.entries(resp.data.personsMap)
        .map(([ uid, person ]) => {
          return [ uid, PersonFactory.build(person as PersonJson) ];
        }));
      const providersMap = Object.fromEntries(Object.entries(resp.data.providersMap)
        .map(([ id, provider ]) => {
          return [ id, ProviderFactory.build(provider as ProviderJson) ];
        }));
      const customCallTypesMap = Object.fromEntries(Object.entries(resp.data.customCallTypesMap)
        .map(([ id, c ]) => {
          return [ id, CallTypeFactory.buildClientCustom(c as ClientCustomCallTypeJson) ];
        }));
      return {
        scheduledMeetings,
        usersMap,
        personsMap,
        providersMap,
        customCallTypesMap,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getAvailableNurses(
    providerId: string,
    requestedCallType: ClientCallType,
    region: string,
    startAt: Date,
    endAt: Date,
    rescheduledMeetingId?: string,
    overrideSchedulingWindow?: boolean,
    managed?: ManagedProperty,
  )
  : Promise<AvailableNurseTimes[]> {
    try {
      const data = {
        providerId,
        requestedCallType: requestedCallType.root.id,
        region,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        rescheduledMeetingId,
        overrideSchedulingWindow,
      };
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'getAvailableNurses',
        managed,
      )(data);
      const { nurses } = resp.data;
      const _nurses = nurses.map((nurse: {
        person: PersonJson,
        availableTimes: Date[],
      }) => {
        return {
          person: PersonFactory.build(nurse.person),
          availableTimes: nurse.availableTimes,
        };
      });
      return _nurses;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createScheduledMeeting(
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
    managed?: ManagedProperty,
    overrideSchedulingWindow?: boolean,
  ): Promise<ScheduledMeeting> {
    try {
      const data = {
        providerId,
        meetingDate,
        requestedCallType: requestedCallType.root.id,
        requestorNote,
        region,
        participants: participants.map(p => p.json()),
        customNotified: customNotified.map(c => c.json()),
        overrideSchedulingWindow,
      };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'createScheduledMeeting',
        managed,
      )(data);
      return ScheduledMeetingFactory.build(res.data.scheduledMeeting);
    } catch (err) {
      throw await this.handleErr(err);
    }
  };

  public async updateScheduledMeetingStatus(
    meetingId: string,
    status: ScheduledMeetingStatus,
    cancelationReason: string | null,
  ): Promise<void> {
    try {
      const data = {
        meetingId,
        status,
        cancelationReason,
      };
      await this.functions.httpsCallable(OnCallFunction.Meeting, 'updateScheduledMeetingStatus')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  };

  public async rescheduleMeeting(
    meetingId: string,
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
    managed?: ManagedProperty,
    overrideSchedulingWindow?: boolean,
  ): Promise<ScheduledMeeting> {
    try {
      const data = {
        meetingId,
        providerId,
        meetingDate,
        requestedCallType: requestedCallType.root.id,
        requestorNote,
        region,
        participants: participants.map(p => p.json()),
        customNotified: customNotified.map(c => c.json()),
        overrideSchedulingWindow,
      };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'rescheduleMeeting',
        managed,
      )(data);
      return ScheduledMeetingFactory.build(res.data.scheduledMeeting);
    } catch (err) {
      throw await this.handleErr(err);
    }
  };

  public async getMeetingsHistory(options?: {
    searchOptions?: { limit?: number, nextPageCursorId?: CursorId },
    managed?: ManagedProperty,
  }): Promise<{
    meetings: Meeting[];
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
    usersMap: Record<string, User>;
    nextPageCursorId: CursorId;
  }> {
    try {
      const data: any = {};
      if (options?.searchOptions?.limit) {
        data.limit = options?.searchOptions?.limit;
      }
      if (options?.searchOptions?.nextPageCursorId) {
        data.nextPageCursorId = options.searchOptions.nextPageCursorId;
      }
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'getMeetingsHistory',
        options?.managed,
      )(data);
      const meetings = resp.data.meetings.map((meeting: MeetingJson) => {
        return MeetingFactory.build(meeting);
      });
      const {
        personsMap,
        providersMap,
        customCallTypesMap,
        usersMap,
      } = this.buildMeetingsMaps(resp.data);
      return {
        meetings,
        personsMap,
        providersMap,
        customCallTypesMap,
        usersMap,
        nextPageCursorId: resp.data.nextPageCursorId,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getCanceledMeetings(options?: {
    searchOptions?: { limit?: number, nextPageCursorId?: CursorId },
    managed?: ManagedProperty,
  }): Promise<{
    canceledMeetings: ScheduledMeeting[];
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
    usersMap: Record<string, User>;
    nextPageCursorId: CursorId;
  }> {
    try {
      const data: any = {};
      if (options?.searchOptions?.limit) {
        data.limit = options?.searchOptions?.limit;
      }
      if (options?.searchOptions?.nextPageCursorId) {
        data.nextPageCursorId = options.searchOptions.nextPageCursorId;
      }
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'getCanceledMeetings',
        options?.managed,
      )(data);
      const canceledMeetings = resp.data.canceledMeetings.map((
        canceledMeeting: ScheduledMeetingJson,
      ) => {
        return ScheduledMeetingFactory.build(canceledMeeting);
      });
      const {
        personsMap,
        providersMap,
        customCallTypesMap,
        usersMap,
      } = this.buildMeetingsMaps(resp.data);
      return {
        canceledMeetings,
        personsMap,
        providersMap,
        customCallTypesMap,
        usersMap,
        nextPageCursorId: resp.data.nextPageCursorId,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  private buildMeetingsMaps(data: {
    personsMap: Record<string, PersonJson>,
    providersMap: Record<string, ProviderJson>,
    customCallTypesMap: Record<string, ClientCustomCallTypeJson>,
    usersMap: Record<string, UserJson>
  }): {
    personsMap: Record<string, Person>;
    providersMap: Record<string, Provider>;
    customCallTypesMap: Record<string, ClientCustomCallType>;
    usersMap: Record<string, User>;
  } {
    const personsMap = Object.fromEntries(
      Object.entries(data.personsMap).map(([ uid, person ]) => {
        return [ uid, PersonFactory.build(person as PersonJson) ];
      }),
    );
    const usersMap = Object.fromEntries(
      Object.entries(data.usersMap).map(([ uid, user ]) => {
        return [ uid, UserFactory.build(user as UserJson) ];
      }),
    );
    const providersMap = Object.fromEntries(
      Object.entries(data.providersMap).map(([ id, provider ]) => {
        return [ id, ProviderFactory.build(provider as ProviderJson) ];
      }),
    );
    const customCallTypesMap = Object.fromEntries(
      Object.entries(data.customCallTypesMap).map(([ id, c ]) => {
        return [ id, CallTypeFactory.buildClientCustom(c as ClientCustomCallTypeJson) ];
      }),
    );
    return {
      personsMap,
      providersMap,
      customCallTypesMap,
      usersMap,
    };
  };

  public async getMeeting(meetingId: string, options?: {
    managed?: ManagedProperty,
  }): Promise<{
    meeting: Meeting | undefined;
    scheduledMeeting: ScheduledMeeting | undefined;
    personsMap: Record<string, Person>;
    usersMap: Record<string, User>;
    provider: Provider | undefined;
    customCallType: ClientCustomCallType | undefined;
  }> {
    try {
      const data: any = { meetingId };
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'getMeeting',
        options?.managed,
      )(data);
      const meeting = resp.data.meeting ? MeetingFactory.build(resp.data.meeting) : undefined;
      const scheduledMeeting = resp.data.scheduledMeeting ?
        ScheduledMeetingFactory.build(resp.data.scheduledMeeting) : undefined;
      const personsMap = Object.fromEntries(
        Object.entries(resp.data.personsMap).map(([ uid, person ]) => {
          return [ uid, PersonFactory.build(person as PersonJson) ];
        }),
      );
      const usersMap = Object.fromEntries(
        Object.entries(resp.data.usersMap).map(([ uid, user ]) => {
          return [ uid, UserFactory.build(user as UserJson) ];
        }),
      );
      const provider = resp.data.provider ?
        ProviderFactory.build(resp.data.provider as ProviderJson) : undefined;
      const customCallType = (resp.data.customCallType ?
        CallTypeFactory.buildClientCustom(resp.data.customCallType as ClientCustomCallTypeJson) :
        undefined);
      return {
        meeting,
        scheduledMeeting,
        personsMap,
        usersMap,
        provider,
        customCallType,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setActiveRegion(
    region: StateAbbreviation,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = { region };
      await this.functions.httpsCallable(OnCallFunction.Core, 'setActiveRegion', managed)(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setActiveTimeZone(timeZone: TimeZone): Promise<void> {
    try {
      const data = { timeZone };
      await this.functions.httpsCallable(OnCallFunction.Core, 'setActiveTimeZone')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateParticipantsAndNotified(
    meetingId: string,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = {
        meetingId,
        participants: participants.map(p => p.json()),
        customNotified: customNotified.map(c => c.json()),
      };
      await this.functions.httpsCallable(
        OnCallFunction.Meeting,
        'updateParticipantsAndNotified',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  };
}
