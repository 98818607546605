import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import { Provider, ProviderFactory, ProviderJson, User, UserFactory, UserJson } from '@pocketrn/entities/dist/core';
import { CallTypeFactory, ClientCustomCallType, ClientCustomCallTypeJson, Meeting, MeetingFactory, MeetingJson, QueuedAcceptor, QueuedRequestor, QueueStats } from '@pocketrn/entities/dist/meeting';

export interface MatchMakingResponse {
  userMatch: UserMatch,
  queuedAcceptor?: QueuedAcceptor,
  queuedRequestor?: QueuedRequestor,
  queueStats?: QueueStats,
}

export interface UserMatchJson {
  usersMap: Record<string, UserJson>;
  personsMap: Record<string, PersonJson>;
  meetings: MeetingJson[];
  providersMap: Record<string, ProviderJson>;
  customCallTypesMap: Record<string, ClientCustomCallTypeJson>;
}

export interface UserMatch {
  usersMap: Record<string, User>;
  personsMap: Record<string, Person>;
  meetings: Meeting[];
  providersMap: Record<string, Provider>;
  customCallTypesMap: Record<string, ClientCustomCallType>;
}

export const buildUserMatch = (userMatchJson: UserMatchJson) : UserMatch => {
  return {
    usersMap: Object.fromEntries(Object.entries(userMatchJson.usersMap)
      .map(([ id, userJson ]) => [ id, UserFactory.build(userJson) ])),
    personsMap: Object.fromEntries(Object.entries(userMatchJson.personsMap)
      .map(([ id, personJson ]) => [ id, PersonFactory.build(personJson) ])),
    meetings: userMatchJson.meetings.map(meetingJson => MeetingFactory.build(meetingJson)),
    providersMap: Object.fromEntries(Object.entries(userMatchJson.providersMap)
      .map(([ id, providerJson ]) => [ id, ProviderFactory.build(providerJson) ])),
    customCallTypesMap: Object.fromEntries(Object.entries(userMatchJson.customCallTypesMap)
      .map(([ id, ccJson ]) => [ id, CallTypeFactory.buildClientCustom(ccJson) ])),
  };
};
