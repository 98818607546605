import { PageController } from './../apps/page-state/controllers/Page.controller';
import {
  ZoomVideoController,
  ZoomVideoClient,
  zoomVideoSDKClient,
} from '@pocketrn/rn-designsystem';
import { logger } from '@pocketrn/client/dist/app-logger';
import {
  MeetingController,
  MeetingRouter,
  ProfileController,
  UserMatchController,
  ShiftController,
} from '../apps/meeting-app/index';
import { firestore } from './firebase';
import { SecureDraftController } from '../apps/secure-drafts/src/controller';
import { meetingSDK, secureDraftSDK } from '../services/firebase';
import store from './store';
import { sessionUserController } from './userStateInit';
import { ScheduledMeetingsController } from '../apps/meeting-app/state/controllers/ScheduledMeetings.controller';
import { userController } from '../state/controllers';

export const meetingRouter = new MeetingRouter();
export const pageController = new PageController(store);
export const profileController = new ProfileController(
  meetingSDK,
  store,
  sessionUserController,
);
export const scheduledMeetingsController = new ScheduledMeetingsController(
  meetingSDK,
  store,
  firestore,
  userController,
  sessionUserController,
);
export const userMatchController = new UserMatchController(
  meetingSDK,
  store,
  firestore,
  sessionUserController,
  scheduledMeetingsController,
  pageController,
);
export const shiftController = new ShiftController(meetingSDK, store);
export const meetingController = new MeetingController(
  meetingSDK,
  store,
  firestore,
  userMatchController,
);
export const zoomVideoController = new ZoomVideoController(
  new ZoomVideoClient(zoomVideoSDKClient, logger),
);
export const secureDraftController = new SecureDraftController(secureDraftSDK);

