import React, { FunctionComponent, lazy, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RelativeTechnicianRoute } from './entities/Router';
import { RouteProps } from './entities/RouteProps';
import ManageProviderWrapper from './components/routes/ManageProviderWrapper';

/* eslint-disable @typescript-eslint/promise-function-async */
const Main = lazy(() => import('./components/routes/MainRoute'));
const CreateProvider = lazy(() => import('./components/routes/CreateProviderRoute'));
const ManageProviders = lazy(() => import('./components/routes/ManageProvidersRoute'));
const ManageProvider = lazy(() => import('./components/routes/ManageProviderRoute'));
const ManageProviderCallTypes = lazy(
  () => import('./components/routes/ManageProviderCallTypesRoute'),
);
const ManageProviderTranslations = lazy(
  () => import('./components/routes/ManageProviderTranslationsRoute'),
);
const ManageProviderOperationHours = lazy(
  () => import('./components/routes/ManageProviderOperationHoursRoute'),
);
const ManageProviderInviteCode = lazy(
  () => import('./components/routes/ManageProviderInviteCodeRoute'),
);
const ManageProviderCredit = lazy(
  () => import('./components/routes/ManageProviderCreditRoute'),
);
const ManageProviderDiscount = lazy(
  () => import('./components/routes/ManageProviderDiscountRoute'),
);
const ManageProviderCustomForms = lazy(
  () => import('./components/routes/ManageProviderCustomFormsRoute'),
);
const InviteUser = lazy(() => import('./components/routes/InviteUserRoute'));
const ManageUsers = lazy(() => import('./components/routes/ManageUsersRoute'));
const PullStats = lazy(() => import('./components/routes/PullStatsRoute'));
const Escalate = lazy(() => import('./components/routes/EscalateRoute'));
const ViewSupportTickets = lazy(() => import('./components/routes/ViewSupportTickets'));
const ViewQueueData = lazy(() => import('./components/routes/ViewQueueData'));
const TechnicianExtras = lazy(() => import('./components/routes/TechnicianExtrasRoute'));
const ManageFeatureFlags = lazy(() => import('./components/routes/ManageFeatureFlagsRoute'));
const SetFeatureFlag = lazy(() => import('./components/routes/SetFeatureFlagRoute'));
/* eslint-enable @typescript-eslint/promise-function-async */

const RootPage: FunctionComponent<RouteProps> = (props): ReactElement => {
  return (
    <Routes>
      <Route index element={<Main />} />
      <Route path={RelativeTechnicianRoute.CreateFeatureFlag} element={
        <SetFeatureFlag {...props} />}
      />
      <Route path={RelativeTechnicianRoute.EditFeatureFlag} element={
        <SetFeatureFlag {...props} />}
      />
      <Route path={RelativeTechnicianRoute.ManageFeatureFlags} element={
        <ManageFeatureFlags {...props} />}
      />
      <Route path={RelativeTechnicianRoute.InviteUser} element={
        <InviteUser {...props} />}
      />
      <Route path={RelativeTechnicianRoute.CreateProvider} element={
        <CreateProvider {...props} />}
      />
      <Route path={RelativeTechnicianRoute.ManageProviders} element={
        <ManageProviders {...props} />}
      />
      <Route path={RelativeTechnicianRoute.ManageProvider} element={
        <ManageProviderWrapper {...props}>
          <ManageProvider {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderCallTypes} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderCallTypes {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderTranslations} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderTranslations {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderOperationHours} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderOperationHours {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderInviteCode} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderInviteCode {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderCredit} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderCredit {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderDiscount} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderDiscount {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageProviderCustomForms} element={
        <ManageProviderWrapper {...props}>
          <ManageProviderCustomForms {...{} as any} />
        </ManageProviderWrapper>}
      />
      <Route path={RelativeTechnicianRoute.ManageUsers} element={
        <ManageUsers {...props} />}
      />
      <Route path={RelativeTechnicianRoute.PullStats} element={
        <PullStats {...props} />}
      />
      <Route path={RelativeTechnicianRoute.Escalate} element={
        <Escalate {...props} />}
      />
      <Route path={RelativeTechnicianRoute.ViewSupportTickets} element={
        <ViewSupportTickets {...props} />}
      />
      <Route path={RelativeTechnicianRoute.ViewQueueData} element={
        <ViewQueueData {...props} />}
      />
      <Route path={RelativeTechnicianRoute.TechnicianExtras} element={
        <TechnicianExtras {...props} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RootPage;
