import { Person } from '@pocketrn/entities/dist/community';
import { Stat, User } from '@pocketrn/entities/dist/core';
import { TechnicianSDK } from '../../services/firebase/TechnicianSDK';
import { Meeting, QueueData } from '@pocketrn/entities/dist/meeting';
import { CursorId } from '@pocketrn/rn-designsystem';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class StatController {
  public sdk: TechnicianSDK;
  public store: ReduxStore;

  constructor(sdk: TechnicianSDK, store: ReduxStore) {
    this.sdk = sdk;
    this.store = store;
  }

  public async retrieveUserByToken(uidToken: string): Promise<{
    user: User,
    person: Person | undefined,
  }> {
    const { user, person } = await this.sdk.getUserByToken(uidToken);
    return { user, person };
  }

  public async retrieveQueueData(): Promise<{
    queueData: QueueData,
    meetings: Meeting[],
  }> {
    const { queueData, meetings } = await this.sdk.getQueueData();
    return { queueData, meetings };
  }

  public async retrieveStats(
    start: Date,
    end: Date,
    nextPageCursorId?: CursorId,
  ): Promise<{stats: Stat[], nextPageCursor: string | undefined}> {
    const resp = await this.sdk.getStats(start, end, nextPageCursorId);
    const stats = resp.stats;
    const nextPageCursor = resp.nextPageCursorId;
    return { stats, nextPageCursor };
  }

  public async escalateMeeting(
    meetingId: string,
    firstName: string,
    lastName: string,
    escalatedAt: Date,
  ): Promise<void> {
    await this.sdk.createEscalationStat(meetingId, firstName, lastName, escalatedAt);
  }
}
