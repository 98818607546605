import { PageRouter } from '@pocketrn/rn-designsystem';

export interface InviteCodeData {
  inviteCode: string | undefined,
  providerId: string | undefined,
}

export enum RelativeAssignmentRoute {
  SignConsents = 'sign-consents',
  Register = 'register',
  ScheduleMeeting = 'schedule-meeting',
  Root = '*',
}

/**
 * @NOTE: when adding new routes, the route path is important to note:
 * 1. Anything that doesn't have subpaths is considered a root path and
 *    will not have a back button.
 * 2. Anything that has subpaths will have a back button that uses the path fragments
 *    as a breadcrumb for the back button.
 */
export enum CoreRoute {
  ActionLinkRoot = '/link/:id/*',
  HelpRoot = '/help/*',
  MainPageRoot = '/main/*',
  MainPage = '/main',
  Register = '/register',
  RegisterZoom = '/register/zoom',
  RegisterTutorials = '/register/tutorials',
  VerifyEmail = '/register/verify-email',
  Login = '/login',
  Signup = '/signup',
  ProfileRoot = '/profile/*',
  MeetingsRoot = '/meetings/*',
  MeetingRoot = '/meeting/*',
  ManageProfile = '/profile',
  Permissions = '/profile/permissions',
  Acknowledgments = '/profile/acknowledgments',
  EditProfile = '/profile/edit',
  EditPhone = '/profile/phones',
  EditAssignments = '/profile/links',
  EditCalendarPreferences = '/profile/calendar',
  ManageClinics = '/profile/clinics',
  ManagePatients = '/profile/patients',
  InvitePatient = '/profile/patients/invite',
  ManageCaregivers = '/profile/caregivers',
  InviteCaregiver = '/profile/caregivers/invite',
  Subscription = '/profile/subscription',
  UpdatePaymentMethod = '/profile/subscription/edit',
  EmergencyInformation = '/profile/emergency-information',
  RegisterPhone = '/profile/finish-registration-add-phone',
  AccountSuspended = '/account-suspended',
  InviteCode = '/invite/:providerId/:inviteCode',
}

export default class CoreRouter extends PageRouter {
  constructor() {
    super('/');
  }

  public static getUserProfile(
    route: string,
    options?: { managedUid?: string, caregiverUid?: string },
  ): string {
    if (!options?.managedUid && !options?.caregiverUid) return route;
    const params = new URLSearchParams();
    if (options?.managedUid) params.set('managedUid', options.managedUid);
    if (options?.caregiverUid) params.set('caregiverUid', options.caregiverUid);
    return `${route}?${params.toString()}`;
  }
}
