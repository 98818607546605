import { doc, Firestore, onSnapshot } from 'firebase/firestore';
import { SessionUserController } from '../apps/user-state';
import { TimeUtils } from '@pocketrn/time-utils';
import { Counter } from '@pocketrn/rn-designsystem';
import { sleep } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';

const MAX_RETRY_ATTEMPTS = 5;

class NoIpEmailUidError extends Error {
  constructor() {
    super('cannot connect to database without a valid user');
  }
}

interface CallbackDateChecks {
  dateKey: string;
  callback: () => void;
  pingFallbackSeconds?: number;
}

interface DateChecks {
  [dateKey: string]: Date;
}

export class SnapshotHandler {
  public firestore: Firestore;
  public sessionUserController: SessionUserController;
  public dateChecks: DateChecks;
  private _unsubscribe: undefined | (() => void) = undefined;
  private counters: Map<string, Counter> = new Map();
  private subscribed = false;
  private retryAttempt = 0;

  constructor(
    firestore: Firestore,
    sessionUserController: SessionUserController,
    dateChecks: DateChecks,
  ) {
    this.firestore = firestore;
    this.sessionUserController = sessionUserController;
    this.dateChecks = dateChecks;
  }

  public async subscribe(
    snapshotCollectionName: string,
    callbackUnsubscribe: () => void,
    callbackDateChecks: CallbackDateChecks[],
    callbackIfSnapshotDoesntExist?: () => void,
  ): Promise<void> {
    try {
      if (this.subscribed) {
        return;
      }
      const user = this.sessionUserController.getStoredActiveUser();
      // @NOTE: we grab the sessionUser uid instead of the firebase.user.uid because
      // this breaks if we are impersonating a user who has never logged in.
      // See: https://github.com/pocketrn/Task-Management/issues/104
      const ipEmailUid = user?.ipEmailUid;
      if (!ipEmailUid) {
        throw new NoIpEmailUidError();
      }
      this._unsubscribe = onSnapshot(doc(
        this.firestore,
        snapshotCollectionName,
        ipEmailUid,
      ), async snapshot => {
        this.retryAttempt = 0;
        this.subscribed = true;
        this.stopManualPing();
        const data = snapshot.data();
        if ((!snapshot.exists() || !data) && callbackIfSnapshotDoesntExist) {
          return callbackIfSnapshotDoesntExist();
        }
        if (!data) {
          return;
        }
        callbackDateChecks.forEach(({ dateKey, callback }) => {
          if (!data[dateKey]) {
            return;
          }
          const _date = TimeUtils.ensureDate(data[dateKey]);
          // @NOTE: two snapshot calls with almost similar timestamps can be from
          // requests that are accidently sent back-to-back (echoes).  So we should
          // ignore dates too close together.  However, users can perform quick successive
          // actions that should be reflected in the callback (e.g. clicking "Accept" as a nurse
          // then quickly clicking "Start Meeting").  So we need to account for reasonable human
          // level reaction times.
          if (
            this.dateChecks[dateKey] &&
            Math.abs(_date.getTime() - this.dateChecks[dateKey].getTime()) < 500
          ) {
            return;
          }
          this.dateChecks[dateKey] = _date;
          callback();
        });
      }, async err => {
        this.subscribed = false;
        this.retryAttempt += 1;
        logger.logError(new Error(`Error connecting to ${snapshotCollectionName} on retry attempt #${this.retryAttempt}.`));
        logger.logError(err);
        await sleep(1000 * this.retryAttempt * this.retryAttempt);
        if (this.retryAttempt >= MAX_RETRY_ATTEMPTS) {
          logger.logError(new Error(`Reached the maximum number of retry attempts to connect to ${snapshotCollectionName}.`));
          this.manuallyPingCallbacks(callbackDateChecks);
          return;
        }
        if (this._unsubscribe) {
          callbackUnsubscribe();
        }
        await this.subscribe(
          snapshotCollectionName,
          callbackUnsubscribe,
          callbackDateChecks,
          callbackIfSnapshotDoesntExist,
        );
      });
    } catch (e) {
      // @NOTE: We return when there's no ipEmailUid due to actionLinks.
      const impersonatedUid = localStorage.getItem('_impersonatedUid');
      if (e instanceof NoIpEmailUidError && !impersonatedUid) {
        return;
      }
      logger.logError(e);
      this.manuallyPingCallbacks(callbackDateChecks);
    }
  }

  public unsubscribe(): void {
    this.subscribed = false;
    this.stopManualPing();
    if (this._unsubscribe) {
      this._unsubscribe();
    }
  }

  private async manuallyPingCallbacks(callbackDateChecks: CallbackDateChecks[]): Promise<void> {
    callbackDateChecks.map(async dateCheck => {
      const { callback, pingFallbackSeconds } = dateCheck;
      if (!pingFallbackSeconds) {
        return;
      }
      const counter = new Counter();
      this.counters.set(dateCheck.dateKey, counter);
      counter.startTick(async () => callback(), pingFallbackSeconds * 1000);
    });
  }

  public stopManualPing(): void {
    this.counters.forEach(counter => counter.stopTick());
    this.counters.clear();
  }
}
