import {
  Account,
  AccountFactory,
  AccountJson,
  AccountType,
  Provider,
  ProviderFactory,
  ProviderJson,
  User,
  UserFactory,
  SignedCustomFormFactory,
  CustomFormFactory,
  ClientSignedCustomFormJson,
  ClientCustomFormJson,
  ClientSignedCustomForm,
  ClientCustomForm,
} from '@pocketrn/entities/dist/core';
import { FirebaseFunctionInterface, FirebaseSDK, ManagedProperty, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';
import { Person, PersonFactory } from '@pocketrn/entities/dist/community';
import { isLocal } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';
import { CallTypeFactory, ClientCustomCallType, ClientCustomCallTypeJson } from '@pocketrn/entities/dist/meeting';

export class SessionUserSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async initLocal(): Promise<void> {
    try {
      if (!isLocal()) {
        logger.logError(new Error('cannot initLocal if not local'));
        return;
      }
      await this.functions.httpsCallable(OnCallFunction.Local, 'initLocal')({});
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUser(managed?: ManagedProperty): Promise<User> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core, 'getUser', managed,
      )({});
      return UserFactory.build(resp.data.user);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getPerson(managed?: ManagedProperty): Promise<Person> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Community, 'getPerson', managed,
      )({});
      return PersonFactory.build(resp.data.person);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getAccounts(providerId?: string, managed?: ManagedProperty): Promise<{
    accounts: Account[];
    providers: Provider[];
    customCallTypes: ClientCustomCallType[];
  }> {
    const data = providerId ? { providerId } : {};
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getAccounts',
        managed,
      )(data);
      return {
        accounts: resp.data.accounts.map(
          (account: AccountJson) => AccountFactory.build(account),
        ),
        providers: resp.data.providers.map(
          (provider: ProviderJson) => {
            SessionUserSDK.conformReducedProvider(provider);
            return ProviderFactory.build(provider);
          },
        ),
        customCallTypes: resp.data.customCallTypes.map(
          (customCallType: ClientCustomCallTypeJson) => CallTypeFactory.buildClient(customCallType),
        ),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public static conformReducedProvider(provider: ProviderJson): void {
    provider.definedCallTypes = provider.definedCallTypes ?? [];
    provider.prnContacts = provider.prnContacts ?? {};
    provider.providerContacts = provider.providerContacts ?? {};
  }

  public async setActiveAccount(type: AccountType, providerId: string): Promise<void> {
    try {
      const data = {
        type,
        providerId: providerId,
      };
      await this.functions.httpsCallable(OnCallFunction.Core, 'setActiveAccount')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async activateUserAccount(
    providerId: string,
    accountType: AccountType,
    inviteCode?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data: any = {
        accountType,
        providerId,
        inviteCode,
      };
      await this.functions.httpsCallable(OnCallFunction.Core, 'activateUserAccount', managed)(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createSnapshotImpersonator(impersonatedUid: string): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Technician, 'createSnapshotImpersonator', {
        uid: impersonatedUid,
        // @NOTE: The accountType doesn't matter
        accountType: AccountType.Patient,
      })({});
    } catch (err) {
      throw await this.handleErr((err as Error));
    }
  }

  public async removeSnapshotImpersonator(): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Technician, 'removeSnapshotImpersonator')({});
    } catch (err) {
      throw await this.handleErr((err as Error));
    }
  }

  public async requestGoogleAPIConsentURI(googleAPIName: string): Promise<string> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Core, 'requestGoogleAPIConsentURI')({ googleAPIName });
      return resp.data.authUrl;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async revokeGoogleAPIAccess(googleAPIName: string): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Core, 'revokeGoogleAPIAccess')({ googleAPIName });
    } catch (err) {
      throw this.handleErr(err);
    }
  }

  public async confirmConsentToGoogleAPI(
    confirmationCode: string,
    googleAPIName: string,
  ): Promise<void> {
    try {
      const data = { googleAPIName, confirmationCode };
      await this.functions.httpsCallable(OnCallFunction.Core, 'confirmConsentToGoogleAPI')(data);
    } catch (err) {
      throw this.handleErr(err);
    }
  }

  public async getMyCustomForms(providerId?: string, managed?: ManagedProperty): Promise<{
    signedCustomForms: ClientSignedCustomForm[];
    customForms: ClientCustomForm[];
  }> {
    try {
      const data = providerId ? { providerId } : {};
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core, 'getMyCustomForms', managed,
      )(data);
      const signedCustomForms = res.data.signedCustomForms.map(
        (f: ClientSignedCustomFormJson) => SignedCustomFormFactory.buildClient(f),
      );
      const customForms = res.data.customForms.map(
        (f: ClientCustomFormJson) => CustomFormFactory.buildClient(f),
      );
      return { signedCustomForms, customForms };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async signCustomForm(
    customFormId: string,
    acceptedBy: string,
    body: string,
    coAcceptedBy?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = {
        customFormId,
        acceptedBy,
        body,
        coAcceptedBy,
      };
      await this.functions.httpsCallable(OnCallFunction.Core, 'signCustomForm', managed)(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
